<template>
  <div class="lp-card">
    <div class="lp"/>
      <img class="arissa-header" alt="arissa-header" src="@/assets/arissa-header.svg">
      <div :class="[mobileView ? 'lp-sh-m' : 'lp-sh']">
        <text :class="[mobileView ? 'landing-page-mobile-subheader' : 'landing-page-subheader']" >ui/ux design | graphic design | illustrator</text>
      </div>
      <div :class="[mobileView ? 'landing-graphic-m' : 'landing-graphic']">
      <svg :width="[mobileView? 300 : 1232]" :height="[mobileView ? 80 : 328]" viewBox="0 0 1232 328" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="landing-animation" clip-path="url(#clip0_204_109)">
      <rect width="1232" height="327.5" fill="white"/>
      <g id="plant-r">
      <g id="plant-r_2">
      <path id="Vector" d="M1083.25 206.86C1083.25 206.86 1081.4 164.08 1094.25 145.02" stroke="#858E69" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="Vector_2" d="M1104.5 184.96C1111.96 184.96 1118 178.916 1118 171.46C1118 164.004 1111.96 157.96 1104.5 157.96C1097.04 157.96 1091 164.004 1091 171.46C1091 178.916 1097.04 184.96 1104.5 184.96Z" fill="#A3AD80" stroke="#858E69" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="Vector_3" d="M1094.2 168.31L1117.24 175.94" stroke="#858E69" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="Vector_4" d="M1099.5 144.96C1106.96 144.96 1113 138.916 1113 131.46C1113 124.004 1106.96 117.96 1099.5 117.96C1092.04 117.96 1086 124.004 1086 131.46C1086 138.916 1092.04 144.96 1099.5 144.96Z" fill="#A3AD80" stroke="#858E69" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="Vector_5" d="M1094 144.96L1106 120.96" stroke="#858E69" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="Vector_6" d="M1069.5 170.96C1076.96 170.96 1083 164.916 1083 157.46C1083 150.004 1076.96 143.96 1069.5 143.96C1062.04 143.96 1056 150.004 1056 157.46C1056 164.916 1062.04 170.96 1069.5 170.96Z" fill="#A3AD80" stroke="#858E69" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="Vector_7" d="M1057 151.96L1083 164.96" stroke="#858E69" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      </g>
      <g id="plant-l">
      <g id="plant-l_2">
      <path id="Vector_8" d="M267.5 100.76C267.5 100.76 288 158.96 284 238.96" stroke="#858E69" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="Vector_9" d="M287.05 111.19C298.16 103.19 316.15 109.29 316.15 109.29C316.15 109.29 316.15 128.29 305.02 136.24C293.89 144.19 275.92 138.15 275.92 138.15C275.92 138.15 275.94 119.17 287.05 111.19Z" fill="#A3AD80" stroke="#858E69" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
      <g id="Vector_10">
      <path d="M276.92 137.15C276.92 137.15 285 126.96 297 122.96Z" fill="#A3AD80"/>
      <path d="M276.92 137.15C276.92 137.15 285 126.96 297 122.96" stroke="#858E69" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <path id="Vector_11" d="M257.8 115.42C270.48 120.53 275 138.97 275 138.97C275 138.97 259 149.13 246.28 143.97C233.56 138.81 229.07 120.48 229.07 120.48C229.07 120.48 245.12 110.31 257.8 115.42Z" fill="#A3AD80" stroke="#858E69" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
      <g id="Vector_12">
      <path d="M252 129.72C252 129.72 266.49 130.36 275 138.97Z" fill="#A3AD80"/>
      <path d="M252 129.72C252 129.72 266.49 130.36 275 138.97" stroke="#858E69" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <path id="Vector_13" d="M300.41 158.8C312.76 152.92 329.41 162.08 329.41 162.08C329.41 162.08 326.03 180.76 313.68 186.64C301.33 192.52 284.68 183.36 284.68 183.36C284.68 183.36 288.07 164.68 300.41 158.8Z" fill="#A3AD80" stroke="#858E69" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
      <g id="Vector_14">
      <path d="M284.68 183.36C284.68 183.36 296.08 172.48 307 172.72Z" fill="#A3AD80"/>
      <path d="M284.68 183.36C284.68 183.36 296.08 172.48 307 172.72" stroke="#858E69" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <path id="Vector_15" d="M259.08 162.61C272.48 165.3 280.31 182.61 280.31 182.61C280.31 182.61 266.4 195.53 253 192.83C239.6 190.13 231.77 172.83 231.77 172.83C231.77 172.83 245.67 159.91 259.08 162.61Z" fill="#A3AD80" stroke="#858E69" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
      <g id="Vector_16">
      <path d="M254.28 177.78C254.28 177.78 269.21 175.38 280.28 182.6Z" fill="#A3AD80"/>
      <path d="M254.28 177.78C254.28 177.78 269.21 175.38 280.28 182.6" stroke="#858E69" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <path id="Vector_17" d="M273.41 71.15C278.35 83.9 267.98 99.8 267.98 99.8C267.98 99.8 249.6 95.04 244.66 82.29C239.72 69.54 250.09 53.63 250.09 53.63C250.09 53.63 268.47 58.4 273.41 71.15Z" fill="#A3AD80" stroke="#858E69" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
      <g id="Vector_18">
      <path d="M259 76.72C261.529 82.7874 263.592 89.0389 265.17 95.42Z" fill="#A3AD80"/>
      <path d="M259 76.72C261.529 82.7874 263.592 89.0389 265.17 95.42" stroke="#858E69" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      </g>
      </g>
      <g id="hair" @click="startWave">
      <path id="Vector_19" d="M751 144.96C714 119.96 707.47 31.69 601.24 59.82C601.24 59.82 568.17 57.96 541.58 102.96C514.99 147.96 469 130.96 467 174.96C465.32 211.96 493.31 216.87 510.45 212.6C507.32 202.23 511.11 192.9 521 199.96C527 204.25 520.65 210.06 510.45 212.6C511.857 217.303 514.458 221.561 518 224.96C527.25 233.72 539.75 231.2 548.1 226.02C543.01 218.32 542.88 209.74 552 210.96C561.12 212.18 556.91 220.57 548.1 226.02C551.38 231.02 556.73 235.59 563 236.96C595 243.96 608.18 217.84 617 214.96L674 208.96C674 208.96 671 233.96 712 234.96C720.74 235.17 726.68 233.85 730.51 231.78C722.64 228 718.18 221.78 724 215.96C731.77 208.19 743.9 224.55 730.51 231.78C737.58 235.18 747.41 236.6 755 233.96C758.487 232.784 761.654 230.818 764.256 228.216C766.858 225.614 768.824 222.447 770 218.96C761 215.63 754.74 208.1 760 203.96C769 196.9 773.78 207.96 770 218.96C778.92 222.28 790.49 221.45 796 208.96C807 183.96 788 169.96 751 144.96Z" fill="#794E2F" stroke="#503A2E" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <g id="body" @click="startWave">
      <path id="Vector_20" d="M599.69 190H623.49C646.957 190 669.464 199.32 686.062 215.91C702.66 232.5 711.989 255.003 712 278.47C712.005 279.851 711.738 281.219 711.213 282.497C710.688 283.774 709.916 284.935 708.942 285.913C707.967 286.892 706.809 287.668 705.534 288.198C704.258 288.727 702.891 289 701.51 289H547.51C546.127 289.003 544.758 288.732 543.48 288.203C542.203 287.675 541.042 286.899 540.065 285.921C539.089 284.942 538.315 283.78 537.789 282.501C537.263 281.223 536.995 279.853 537 278.47V252.66C537.008 236.039 543.616 220.101 555.372 208.351C567.128 196.601 583.069 190 599.69 190V190Z" fill="#D68D80" stroke="#B97265" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <g id="Layer 24">
      <path id="Vector_21" d="M1229 289.46H3V324.46H1229V289.46Z" fill="#F1E8E6" stroke="#E9D8D3" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <g id="Layer 23">
      <path id="Vector_22" d="M621.5 231.96C630.613 231.96 638 224.573 638 215.46C638 206.347 630.613 198.96 621.5 198.96C612.387 198.96 605 206.347 605 215.46C605 224.573 612.387 231.96 621.5 231.96Z" fill="#F2BF98" stroke="#DEA881" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <g id="arm" @click="startWave">
      <g id="arm_2">
      <g id="Vector_23">
      <path d="M527 228.96C527 228.96 484 193.96 455.61 123.62C455.61 123.62 451.61 111.7 458.72 110.62C465.83 109.54 466.03 112.62 469.05 119.75C472.07 126.88 462.4 108.94 468.79 105.4C475.18 101.86 478.59 105.15 483.04 114.24C487.49 123.33 474.38 103.93 480.04 99.86C485.7 95.79 491.09 103.08 494.12 109.26C497.15 115.44 545.45 197.57 569.79 208.64" fill="#F2BF98"/>
      <path d="M527 228.96C527 228.96 484 193.96 455.61 123.62C455.61 123.62 451.61 111.7 458.72 110.62C465.83 109.54 466.03 112.62 469.05 119.75C472.07 126.88 462.4 108.94 468.79 105.4C475.18 101.86 478.59 105.15 483.04 114.24C487.49 123.33 474.38 103.93 480.04 99.86C485.7 95.79 491.09 103.08 494.12 109.26C497.15 115.44 545.45 197.57 569.79 208.64" stroke="#DEA881" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <g id="Vector_24">
      <path d="M572.82 210.63L558.82 194.76L517.29 222.03L543 247.96" fill="#D68D80"/>
      <path d="M572.82 210.63L558.82 194.76L517.29 222.03L543 247.96" stroke="#B97265" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      </g>
      </g>
      <g id="leaf-r">
      <g id="leaf-r_2">
      <path id="Vector_25" d="M649.35 62.32C642.35 67.67 630.26 63.64 630.26 63.64C630.26 63.64 629.61 50.95 636.65 45.64C643.69 40.33 655.74 44.32 655.74 44.32C655.74 44.32 656.39 56.97 649.35 62.32Z" fill="#A3AD80" stroke="#858E69" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
      <g id="Vector_26">
      <path d="M629.74 63.64C629.74 63.64 636 54.96 643 53.96Z" fill="#A3AD80"/>
      <path d="M629.74 63.64C629.74 63.64 636 54.96 643 53.96" stroke="#858E69" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      </g>
      </g>
      <g id="leaf-l">
      <g id="leaf-l_2">
      <path id="Vector_27" d="M610.65 62.32C617.65 67.67 629.74 63.64 629.74 63.64C629.74 63.64 630.39 50.95 623.35 45.64C616.31 40.33 604.26 44.32 604.26 44.32C604.26 44.32 603.61 56.97 610.65 62.32Z" fill="#A3AD80" stroke="#858E69" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
      <g id="Vector_28">
      <path d="M628 63.64C628 63.64 621.74 54.96 614.74 53.96Z" fill="#A3AD80"/>
      <path d="M628 63.64C628 63.64 621.74 54.96 614.74 53.96" stroke="#858E69" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      </g>
      </g>
      <g id="mouse">
      <g id="mouse_2">
      <path id="Vector_29" d="M629 283.96C629 270.15 614 258.96 590 258.96C583.37 258.96 577.011 261.594 572.322 266.282C567.634 270.971 565 277.33 565 283.96H629Z" fill="#AB9D9A" stroke="#948885" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
      <g id="Vector_30">
      <path d="M578.68 283.96C578.68 283.96 577.21 263.34 591.61 263.15Z" fill="#AB9D9A"/>
      <path d="M578.68 283.96C578.68 283.96 577.21 263.34 591.61 263.15" stroke="#948885" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <g id="Vector_31">
      <path d="M596 263.96C596 263.96 606 270.96 607 282.96Z" fill="#AB9D9A"/>
      <path d="M596 263.96C596 263.96 606 270.96 607 282.96" stroke="#948885" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <path id="Vector_32" d="M722.13 262.82C726.83 278.63 708.89 280.42 683.96 282.52C659.03 284.62 626.4 271.05 610.55 274.75C594.7 278.45 599.11 265.24 608.06 263.85C608.06 263.85 589.38 272.68 588.67 266.62C587.86 259.74 591.67 260.35 600.67 254.17C600.67 254.17 584.08 265.83 581.04 263.04C575.82 258.27 578.13 242.46 612.04 240.65C645.95 238.84 717.43 247.01 722.13 262.82Z" fill="#F2BF98" stroke="#DEA881" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      </g>
      <g id="Layer 13">
      <g id="Vector_33">
      <path d="M978 283.96H948.5L946.5 252.46L944.5 220.96H976H974.5H1006L1004 252.46L1002 283.96H972.5" fill="#AFBED3"/>
      <path d="M978 283.96H948.5L946.5 252.46L944.5 220.96H976H974.5H1006L1004 252.46L1002 283.96H972.5" stroke="#9AADC7" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      </g>
      <g id="paintbrush" @click="swirlPaintbrush">
      <path id="Vector_34" d="M973.74 238.54C975.29 233.25 999.07 168.22 1004.37 169.77C1009.67 171.32 994.48 238.88 992.93 244.18C992.56 245.44 991.945 246.615 991.121 247.637C990.296 248.66 989.279 249.51 988.126 250.139C986.973 250.768 985.707 251.164 984.402 251.304C983.096 251.444 981.775 251.325 980.515 250.955C979.255 250.585 978.08 249.97 977.058 249.145C976.035 248.321 975.185 247.303 974.556 246.151C973.927 244.998 973.531 243.732 973.391 242.426C973.251 241.121 973.37 239.8 973.74 238.54V238.54Z" fill="#D68D80" stroke="#B97265" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="Vector_35" d="M968.21 257.33C968.921 254.913 970.563 252.876 972.775 251.67C974.987 250.463 977.588 250.184 980.005 250.895C982.422 251.606 984.459 253.248 985.665 255.46C986.872 257.672 987.151 260.273 986.44 262.69C984.96 267.69 972.51 276.42 972.51 276.42C972.51 276.42 966.74 262.37 968.21 257.33Z" fill="#794E2F" stroke="#503A2E" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="Vector_36" d="M976.68 262.22C973.921 261.408 970.996 261.336 968.2 262.01C969.11 268.14 972.51 276.42 972.51 276.42C972.51 276.42 979.85 271.29 983.93 266.64C981.945 264.553 979.444 263.028 976.68 262.22V262.22Z" fill="#A3AD80" stroke="#858E69" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <g id="Layer 11">
      <path id="Vector_37" d="M978 283.96H948.5L946.5 252.46L944.5 220.96H976H974.5H1006L1004 252.46L1002 283.96H972.5" stroke="#9AADC7" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <g id="misc">
      <g id="Layer 30">
      <path id="Vector_38" d="M895 231.96H770V247.96H895V231.96Z" fill="#C7996B" stroke="#B78558" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <g id="Layer 26">
      <path id="Vector_39" d="M906 145.96H758V228.96H906V145.96Z" fill="#E9D8D3" stroke="#E3D3CE" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <g id="Layer 18">
      <path id="Vector_40" d="M550.61 263.96H341.76V283.18H550.61V263.96Z" fill="#AB9D9A" stroke="#948885" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <g id="Layer 17">
      <path id="Vector_41" d="M496 114.16H341.76V262.96H496V114.16Z" fill="#AB9D9A" stroke="#948885" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <g id="Layer 16">
      <path id="Vector_42" d="M413.74 203.12C420.246 203.12 425.52 196.606 425.52 188.57C425.52 180.534 420.246 174.02 413.74 174.02C407.234 174.02 401.96 180.534 401.96 188.57C401.96 196.606 407.234 203.12 413.74 203.12Z" fill="#F1E8E6" stroke="#E9D8D3" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
      <g id="Vector_43">
      <path d="M415.52 176.68C415.52 176.68 414.92 169.98 418.38 169.09Z" fill="#F1E8E6"/>
      <path d="M415.52 176.68C415.52 176.68 414.92 169.98 418.38 169.09" stroke="#E9D8D3" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      </g>
      <g id="Layer 15">
      <path id="Vector_44" d="M795 283.96H778L815 138.96H833L795 283.96Z" fill="#C7996B" stroke="#B78558" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <g id="Layer 14">
      <path id="Vector_45" d="M874 283.96H891L854 138.96H836L874 283.96Z" fill="#C7996B" stroke="#B78558" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <g id="Layer 10">
      <g id="Vector_46">
      <path d="M208 282.96H98C93.7565 282.96 89.6869 281.274 86.6863 278.274C83.6857 275.273 82 271.203 82 266.96C82 262.717 83.6857 258.647 86.6863 255.646C89.6869 252.646 93.7565 250.96 98 250.96H208" fill="#AFBED3"/>
      <path d="M208 282.96H98C93.7565 282.96 89.6869 281.274 86.6863 278.274C83.6857 275.273 82 271.203 82 266.96C82 262.717 83.6857 258.647 86.6863 255.646C89.6869 252.646 93.7565 250.96 98 250.96H208" stroke="#9AADC7" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <path id="Vector_47" d="M199 266.96H82" stroke="#9AADC7" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="Vector_48" d="M204 282.96V250.96" stroke="#9AADC7" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <g id="Layer 9">
      <g id="Vector_49">
      <path d="M91 211.96H201C205.243 211.96 209.313 213.646 212.314 216.646C215.314 219.647 217 223.717 217 227.96C217 232.203 215.314 236.273 212.314 239.274C209.313 242.274 205.243 243.96 201 243.96H91" fill="#F7C65F"/>
      <path d="M91 211.96H201C205.243 211.96 209.313 213.646 212.314 216.646C215.314 219.647 217 223.717 217 227.96C217 232.203 215.314 236.273 212.314 239.274C209.313 242.274 205.243 243.96 201 243.96H91" stroke="#EDB642" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <path id="Vector_50" d="M100 227.96H217" stroke="#EDB642" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="Vector_51" d="M95 211.96V243.96" stroke="#EDB642" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      </g>
      <g id="sparkle-r">
      <path id="Vector_52" d="M804.23 72.03C797.23 68.37 795.08 65.8 791.9 57.56C791.61 56.8043 791.098 56.1543 790.431 55.6957C789.765 55.2372 788.974 54.9917 788.165 54.9917C787.356 54.9917 786.565 55.2372 785.899 55.6957C785.232 56.1543 784.72 56.8043 784.43 57.56C781.25 65.8 779.06 68.37 772.1 72.03C771.455 72.3693 770.915 72.8784 770.538 73.5023C770.161 74.1262 769.962 74.8412 769.962 75.57C769.962 76.2988 770.161 77.0138 770.538 77.6377C770.915 78.2616 771.455 78.7707 772.1 79.11C779.1 82.77 781.25 85.34 784.43 93.58C784.72 94.3357 785.232 94.9857 785.899 95.4443C786.565 95.9028 787.356 96.1483 788.165 96.1483C788.974 96.1483 789.765 95.9028 790.431 95.4443C791.098 94.9857 791.61 94.3357 791.9 93.58C795.08 85.34 797.27 82.77 804.23 79.11C804.875 78.7707 805.415 78.2616 805.792 77.6377C806.168 77.0138 806.368 76.2988 806.368 75.57C806.368 74.8412 806.168 74.1262 805.792 73.5023C805.415 72.8784 804.875 72.3693 804.23 72.03ZM788.17 82.77C786.494 79.9499 784.262 77.5002 781.61 75.57C784.262 73.6398 786.494 71.1901 788.17 68.37C789.839 71.1922 792.068 73.6426 794.72 75.57C792.068 77.4974 789.839 79.9478 788.17 82.77V82.77Z" fill="#EEB743"/>
      <path id="Vector_53" d="M794.72 75.57C792.068 77.4974 789.839 79.9478 788.17 82.77C786.494 79.9499 784.262 77.5002 781.61 75.57C784.262 73.6398 786.494 71.1901 788.17 68.37C789.839 71.1922 792.068 73.6426 794.72 75.57V75.57Z" fill="#F7C65F"/>
      </g>
      <g id="sparkle-l">
      <path id="Vector_54" d="M482.17 41.14C481.361 41.1417 480.57 40.8979 479.902 40.4408C479.235 39.9837 478.721 39.3349 478.43 38.58C475.25 30.34 473.06 27.77 466.1 24.11C465.455 23.7707 464.915 23.2616 464.538 22.6377C464.162 22.0138 463.962 21.2988 463.962 20.57C463.962 19.8412 464.162 19.1262 464.538 18.5023C464.915 17.8784 465.455 17.3693 466.1 17.03C473.1 13.37 475.25 10.8 478.43 2.56C478.72 1.80432 479.232 1.15428 479.899 0.695731C480.565 0.237184 481.356 -0.00830078 482.165 -0.00830078C482.974 -0.00830078 483.765 0.237184 484.431 0.695731C485.098 1.15428 485.61 1.80432 485.9 2.56C489.08 10.8 491.27 13.37 498.23 17.03C498.875 17.3693 499.415 17.8784 499.792 18.5023C500.169 19.1262 500.368 19.8412 500.368 20.57C500.368 21.2988 500.169 22.0138 499.792 22.6377C499.415 23.2616 498.875 23.7707 498.23 24.11C491.23 27.77 489.08 30.34 485.9 38.58C485.609 39.3333 485.097 39.981 484.432 40.438C483.766 40.8949 482.977 41.1396 482.17 41.14Z" fill="#EEB743"/>
      <path id="Vector_55" d="M488.72 20.57C486.068 22.4974 483.839 24.9478 482.17 27.77C480.494 24.9499 478.262 22.5002 475.61 20.57C478.262 18.6398 480.494 16.1901 482.17 13.37C483.839 16.1922 486.068 18.6426 488.72 20.57V20.57Z" fill="#F7C65F"/>
      </g>
      <g id="Layer 6">
      <path id="Vector_56" d="M288 236.74V217.96H276V236.74C270.982 238.067 266.544 241.017 263.378 245.131C260.213 249.244 258.498 254.289 258.5 259.48V282.98H305.5V259.48C305.502 254.289 303.787 249.244 300.622 245.131C297.456 241.017 293.018 238.067 288 236.74Z" fill="#D68D80" stroke="#B97265" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <g id="Layer 5">
      <path id="Vector_57" d="M1092.75 205.48V195.93H1073.75V205.48C1067.78 207.473 1062.59 211.292 1058.91 216.398C1055.23 221.504 1053.25 227.637 1053.25 233.93V283.93H1113.25V233.93C1113.25 227.637 1111.27 221.504 1107.59 216.398C1103.91 211.292 1098.72 207.473 1092.75 205.48V205.48Z" fill="#F7C65F" stroke="#EDB642" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <g id="face" @click="startWave">
      <path id="Vector_58" d="M655.5 109.96C659.09 109.96 662 107.05 662 103.46C662 99.8701 659.09 96.96 655.5 96.96C651.91 96.96 649 99.8701 649 103.46C649 107.05 651.91 109.96 655.5 109.96Z" fill="#794E2F" stroke="#DDA780" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="Vector_59" d="M564 110.96C564 110.96 594 105.11 601 86.96C601 86.96 617.37 114.96 652.68 110.96C652.68 110.96 663 131.96 695 130.96C695 130.96 725 226.96 630 222.96C547 219.48 539 165.96 564 110.96Z" fill="#F1BE96" stroke="#DDA780" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="Vector_60" d="M674 208.96C684.493 208.96 693 200.453 693 189.96C693 179.467 684.493 170.96 674 170.96C663.507 170.96 655 179.467 655 189.96C655 200.453 663.507 208.96 674 208.96Z" fill="#ECB397"/>
      <path id="Vector_61" d="M573 196.96C583.493 196.96 592 188.453 592 177.96C592 167.467 583.493 158.96 573 158.96C562.507 158.96 554 167.467 554 177.96C554 188.453 562.507 196.96 573 196.96Z" fill="#ECB397"/>
      <path id="Vector_62" d="M564 110.96C564 110.96 594 105.11 601 86.96C601 86.96 617.37 114.96 652.68 110.96C652.68 110.96 663 131.96 695 130.96C695 130.96 725 226.96 630 222.96C547 219.48 539 165.96 564 110.96Z" stroke="#DDA780" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="Vector_63" d="M670.18 185.74L667.93 189.34" stroke="#D7766F" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="Vector_64" d="M680.07 188.58L677.82 192.18" stroke="#D7766F" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="Vector_65" d="M577.86 174.79L578.26 179.02" stroke="#D7766F" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="Vector_66" d="M567.74 172.91L568.14 177.13" stroke="#D7766F" stroke-width="6" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <g id="eyes" @click="startWave">
      <g id="eyes_2">
      <path id="Vector_67" d="M590.97 158.9C593.715 158.9 595.94 156.675 595.94 153.93C595.94 151.185 593.715 148.96 590.97 148.96C588.225 148.96 586 151.185 586 153.93C586 156.675 588.225 158.9 590.97 158.9Z" fill="#2F2A1B"/>
      <path id="Vector_68" d="M662.85 163.96C665.595 163.96 667.82 161.735 667.82 158.99C667.82 156.245 665.595 154.02 662.85 154.02C660.105 154.02 657.88 156.245 657.88 158.99C657.88 161.735 660.105 163.96 662.85 163.96Z" fill="#2F2A1B"/>
      </g>
      </g>
      <g id="mouth" @click="startWave">
      <path id="mouth_2" d="M613 166.96C613 166.96 627 176.96 642 167.96" stroke="#2F2A1B" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <g id="bubble-2">
      <path id="Vector_69" d="M1165.15 5H738.853C731.466 5 724.381 7.93452 719.158 13.158C713.934 18.3814 711 25.4659 711 32.8529V32.8529C711.009 40.0197 713.78 46.9073 718.737 52.0833C723.694 57.2594 730.455 60.3254 737.615 60.644C736.016 64.5124 733.045 71.0837 730.002 74.6014C726.453 78.7277 758.422 85.7322 767.139 60.6749H1165.15C1172.53 60.6749 1179.62 57.7404 1184.84 52.517C1190.07 47.2935 1193 40.209 1193 32.822C1192.99 25.4403 1190.05 18.3637 1184.83 13.147C1179.61 7.93026 1172.53 5 1165.15 5Z" fill="#E7EFFB" stroke="#DDE8F6" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="Vector_70" d="M733.365 30.0882L735.531 34.9367L737.852 30.0882H739.915L742.195 35.0605L744.485 30.0676H748.137L743.34 39.2384H741.164L738.874 34.194L736.676 39.2384H734.458L729.744 30.0676L733.365 30.0882Z" fill="#2F2A1B"/>
      <path id="Vector_71" d="M749.251 24.3113H752.511V31.2436C752.8 30.8841 753.149 30.577 753.543 30.3358C754.12 30.0201 754.773 29.8667 755.431 29.8922C755.924 29.863 756.418 29.9349 756.883 30.1034C757.348 30.2719 757.773 30.5335 758.133 30.8723C758.457 31.2088 758.702 31.6126 758.852 32.0548C759.002 32.4969 759.052 32.9667 759 33.4306V39.2591H755.719V34.6272C755.793 34.0025 755.647 33.3717 755.307 32.8426C755.174 32.7061 755.012 32.6004 754.834 32.5327C754.656 32.465 754.465 32.437 754.275 32.4506C753.845 32.4321 753.423 32.5716 753.089 32.8426C752.897 33.0396 752.749 33.2744 752.653 33.5319C752.557 33.7894 752.516 34.0641 752.532 34.3384V39.2591H749.251V24.3113Z" fill="#2F2A1B"/>
      <path id="Vector_72" d="M764.55 38.0109L760.124 30.0883H763.941L766.376 34.7923L768.759 30.0883H772.514L765.148 43.9528H761.476L764.55 38.0109Z" fill="#2F2A1B"/>
      <path id="Vector_73" d="M778.414 30.0883H781.695V31.2437C782.035 30.7979 782.478 30.4418 782.987 30.2063C783.496 29.9708 784.054 29.863 784.614 29.8923C785.107 29.8655 785.601 29.9384 786.066 30.1068C786.53 30.2752 786.956 30.5356 787.317 30.8723C787.645 31.2055 787.894 31.6088 788.044 32.0519C788.194 32.4951 788.242 32.9665 788.184 33.4306V39.2591H784.903V34.6273C784.985 34.0021 784.838 33.3685 784.49 32.8426C784.355 32.71 784.193 32.6067 784.015 32.5392C783.838 32.4718 783.649 32.4416 783.459 32.4506C783.017 32.4415 782.587 32.5958 782.252 32.8839C781.891 33.2806 781.698 33.8023 781.715 34.3384V39.2591H778.435L778.414 30.0883Z" fill="#2F2A1B"/>
      <path id="Vector_74" d="M799.644 38.0522C799.097 38.5741 798.449 38.9781 797.739 39.239C797.029 39.4998 796.273 39.612 795.518 39.5686C794.763 39.6139 794.007 39.5026 793.296 39.2416C792.586 38.9806 791.938 38.5757 791.392 38.0522C790.933 37.6172 790.57 37.092 790.325 36.5096C790.08 35.9272 789.959 35.3003 789.968 34.6685C789.958 34.0397 790.079 33.4156 790.324 32.8364C790.569 32.2573 790.933 31.7358 791.392 31.3056C791.945 30.792 792.594 30.3934 793.303 30.1331C794.011 29.8727 794.764 29.7559 795.518 29.7891C797.04 29.7322 798.522 30.277 799.644 31.3056C800.101 31.7367 800.463 32.2586 800.706 32.8377C800.95 33.4169 801.07 34.0405 801.058 34.6685C801.067 35.2993 800.946 35.9251 800.703 36.5072C800.46 37.0893 800.099 37.6152 799.644 38.0522ZM794.084 33.1728C793.884 33.3666 793.724 33.5991 793.616 33.8562C793.508 34.1133 793.453 34.3896 793.455 34.6685C793.468 35.2166 793.694 35.7381 794.084 36.1231C794.277 36.3193 794.506 36.4751 794.759 36.5814C795.013 36.6877 795.285 36.7423 795.559 36.742C795.831 36.7396 796.099 36.6836 796.348 36.5774C796.598 36.4712 796.824 36.3168 797.014 36.1231C797.211 35.9349 797.37 35.7095 797.479 35.4599C797.589 35.2102 797.648 34.9412 797.654 34.6685C797.64 34.1065 797.411 33.5712 797.014 33.1728C796.618 32.8031 796.101 32.5904 795.559 32.5744C795.012 32.5901 794.488 32.8025 794.084 33.1728V33.1728Z" fill="#2F2A1B"/>
      <path id="Vector_75" d="M806.577 32.7807V39.2591H803.296V32.7807H802.265V30.0883H803.296V27.076H806.577V30.0883H808.444V32.7807H806.577Z" fill="#2F2A1B"/>
      <path id="Vector_76" d="M815.046 36.2263C815.442 36.522 815.885 36.7487 816.356 36.8968C816.765 37.0359 817.193 37.1124 817.625 37.1237C817.925 37.1331 818.22 37.0502 818.471 36.8865C818.53 36.8316 818.575 36.7641 818.604 36.689C818.632 36.6139 818.643 36.5332 818.636 36.4532C818.643 36.3763 818.632 36.2986 818.604 36.2269C818.575 36.1551 818.529 36.0912 818.471 36.0406C818.211 35.8662 817.915 35.7533 817.604 35.7105L816.748 35.5041C816.147 35.4083 815.589 35.1312 815.149 34.7098C814.75 34.2831 814.535 33.7159 814.551 33.1315C814.562 32.3139 814.878 31.53 815.438 30.9342C816.346 30.1533 817.523 29.7573 818.719 29.8304C819.725 29.8298 820.72 30.0297 821.648 30.4184L820.545 32.4816C820.006 32.2187 819.42 32.0645 818.822 32.0277C818.555 32.015 818.29 32.0795 818.058 32.2134C817.972 32.2531 817.898 32.3171 817.847 32.3976C817.796 32.4782 817.769 32.5718 817.77 32.6673C817.77 32.7842 817.808 32.8979 817.879 32.991C817.95 33.0841 818.049 33.1516 818.162 33.1831C818.521 33.2875 818.886 33.3736 819.255 33.441L819.843 33.606C820.338 33.7513 820.798 33.9971 821.194 34.3281C821.659 34.821 821.911 35.4769 821.896 36.154C821.907 36.5766 821.831 36.9969 821.673 37.389C821.515 37.7811 821.278 38.1367 820.978 38.4338C820.036 39.2385 818.82 39.6487 817.584 39.5789C816.283 39.5957 815.004 39.2376 813.901 38.5473L815.046 36.2263Z" fill="#2F2A1B"/>
      <path id="Vector_77" d="M830.489 39.0527C829.782 39.4076 829 39.5847 828.209 39.5685C826.869 39.5971 825.572 39.0968 824.599 38.1759C824.149 37.7262 823.793 37.1922 823.55 36.6044C823.308 36.0167 823.184 35.3868 823.185 34.751C823.185 34.0914 823.315 33.4382 823.568 32.8291C823.821 32.22 824.192 31.6671 824.661 31.2024C825.665 30.2783 826.989 29.7792 828.354 29.8097C829.096 29.8059 829.828 29.9792 830.489 30.3152V33.0695C829.999 32.6947 829.403 32.4852 828.787 32.4712C828.491 32.461 828.195 32.5125 827.92 32.6227C827.645 32.7328 827.395 32.899 827.188 33.1108C826.982 33.3184 826.82 33.5649 826.71 33.8358C826.6 34.1067 826.545 34.3968 826.548 34.6891C826.551 35.2541 826.756 35.7994 827.126 36.2262C827.341 36.4542 827.602 36.6337 827.892 36.7529C828.182 36.8721 828.494 36.9281 828.808 36.9174C829.425 36.9331 830.025 36.7158 830.489 36.3087V39.0527Z" fill="#2F2A1B"/>
      <path id="Vector_78" d="M832.356 30.0883H835.637V31.5944C835.922 31.0434 836.373 30.596 836.926 30.3152C837.454 30.0535 838.039 29.926 838.628 29.9438H839.288V33.0386C838.853 32.8105 838.367 32.6969 837.875 32.7085C837.329 32.6773 836.791 32.8542 836.369 33.2037C836.113 33.4796 835.916 33.8049 835.79 34.1597C835.664 34.5144 835.612 34.8912 835.637 35.2669V39.1869H832.356V30.0883Z" fill="#2F2A1B"/>
      <path id="Vector_79" d="M849.563 38.0521C849.016 38.5741 848.367 38.9781 847.657 39.239C846.948 39.4998 846.192 39.612 845.437 39.5686C844.682 39.6139 843.925 39.5025 843.215 39.2416C842.505 38.9806 841.857 38.5757 841.31 38.0521C840.852 37.6172 840.489 37.092 840.244 36.5096C839.999 35.9272 839.877 35.3003 839.887 34.6685C839.876 34.0397 839.998 33.4156 840.243 32.8364C840.488 32.2572 840.852 31.7358 841.31 31.3055C841.863 30.791 842.512 30.3918 843.221 30.1314C843.929 29.871 844.683 29.7547 845.437 29.7891C846.958 29.7322 848.441 30.277 849.563 31.3055C850.019 31.7379 850.379 32.26 850.623 32.8388C850.866 33.4177 850.986 34.0407 850.976 34.6685C850.985 35.2993 850.865 35.9251 850.621 36.5072C850.378 37.0893 850.018 37.6152 849.563 38.0521V38.0521ZM844.003 33.1727C843.8 33.3657 843.639 33.5978 843.529 33.8549C843.42 34.1121 843.363 34.3889 843.363 34.6685C843.38 35.2178 843.61 35.7392 844.003 36.1231C844.195 36.3193 844.425 36.4751 844.678 36.5814C844.931 36.6876 845.203 36.7423 845.478 36.742C845.749 36.7395 846.017 36.6836 846.267 36.5774C846.517 36.4712 846.743 36.3168 846.933 36.1231C847.13 35.9349 847.288 35.7095 847.398 35.4599C847.508 35.2102 847.567 34.9412 847.572 34.6685C847.558 34.1065 847.329 33.5712 846.933 33.1727C846.537 32.8031 846.02 32.5904 845.478 32.5744C844.93 32.5901 844.407 32.8025 844.003 33.1727V33.1727Z" fill="#2F2A1B"/>
      <path id="Vector_80" d="M856.145 24.3113V39.2591H852.864V24.3113H856.145Z" fill="#2F2A1B"/>
      <path id="Vector_81" d="M861.664 24.3113V39.2591H858.383V24.3113H861.664Z" fill="#2F2A1B"/>
      <path id="Vector_82" d="M879.056 39.2591H875.776V38.2275C875.449 38.6491 875.028 38.9876 874.545 39.2153C874.063 39.4429 873.534 39.5534 873.001 39.5376C872.407 39.5543 871.815 39.4505 871.262 39.2324C870.709 39.0143 870.205 38.6865 869.782 38.2688C869.306 37.7887 868.93 37.2185 868.677 36.5915C868.424 35.9644 868.298 35.2932 868.307 34.617C868.297 33.9419 868.424 33.2717 868.681 32.6474C868.939 32.0231 869.32 31.4577 869.803 30.9857C870.684 30.1749 871.845 29.7352 873.042 29.7581C873.556 29.7513 874.065 29.8535 874.536 30.058C875.008 30.2625 875.43 30.5646 875.776 30.9445V24.2804H879.056V39.2591ZM875.178 36.2262C875.391 36.0257 875.562 35.7846 875.68 35.5171C875.799 35.2496 875.863 34.9611 875.869 34.6685C875.844 34.1209 875.629 33.5991 875.26 33.1934C875.065 32.9947 874.832 32.8374 874.575 32.7311C874.318 32.6247 874.042 32.5714 873.764 32.5744C873.493 32.5728 873.224 32.6249 872.972 32.7276C872.721 32.8302 872.492 32.9815 872.299 33.1727C871.897 33.5918 871.672 34.1496 871.67 34.7304C871.683 35.2725 871.909 35.7876 872.299 36.1643C872.49 36.3593 872.718 36.5144 872.97 36.6206C873.221 36.7269 873.491 36.7822 873.764 36.7833C874.286 36.7672 874.785 36.5702 875.178 36.2262V36.2262Z" fill="#2F2A1B"/>
      <path id="Vector_83" d="M890.538 38.0521C889.991 38.5741 889.342 38.9781 888.632 39.239C887.922 39.4998 887.167 39.612 886.412 39.5686C885.656 39.6139 884.9 39.5025 884.19 39.2416C883.48 38.9806 882.831 38.5757 882.285 38.0521C881.827 37.6172 881.464 37.092 881.219 36.5096C880.974 35.9272 880.852 35.3003 880.862 34.6685C880.851 34.0397 880.972 33.4156 881.218 32.8364C881.463 32.2572 881.826 31.7358 882.285 31.3055C882.838 30.791 883.487 30.3918 884.196 30.1314C884.904 29.871 885.657 29.7547 886.412 29.7891C887.933 29.7322 889.415 30.277 890.538 31.3055C890.995 31.7367 891.357 32.2586 891.6 32.8377C891.843 33.4168 891.963 34.0404 891.951 34.6685C891.96 35.2993 891.839 35.9251 891.596 36.5072C891.353 37.0893 890.993 37.6152 890.538 38.0521ZM884.978 33.1727C884.775 33.3657 884.614 33.5978 884.504 33.8549C884.394 34.1121 884.338 34.3889 884.338 34.6685C884.355 35.2178 884.584 35.7392 884.978 36.1231C885.17 36.3193 885.399 36.4751 885.653 36.5814C885.906 36.6876 886.178 36.7423 886.453 36.742C886.724 36.7395 886.992 36.6836 887.242 36.5774C887.491 36.4712 887.717 36.3168 887.907 36.1231C888.105 35.9349 888.263 35.7095 888.373 35.4599C888.483 35.2102 888.542 34.9412 888.547 34.6685C888.533 34.1065 888.304 33.5712 887.907 33.1727C887.511 32.8031 886.994 32.5904 886.453 32.5744C885.905 32.5901 885.381 32.8025 884.978 33.1727V33.1727Z" fill="#2F2A1B"/>
      <path id="Vector_84" d="M896.377 30.0882L898.553 34.9367L900.874 30.0882H902.886L905.187 35.0605L907.487 30.0676H911.139L906.342 39.2384H904.155L901.855 34.194L899.657 39.2384H897.45L892.735 30.0676L896.377 30.0882Z" fill="#2F2A1B"/>
      <path id="Vector_85" d="M912.273 30.0882H915.554V31.2436C915.894 30.7979 916.337 30.4418 916.846 30.2063C917.355 29.9708 917.913 29.863 918.473 29.8922C918.967 29.866 919.46 29.9393 919.924 30.1076C920.389 30.276 920.814 30.536 921.176 30.8723C921.505 31.2055 921.753 31.6088 921.903 32.0519C922.053 32.495 922.101 32.9664 922.043 33.4306V39.2591H918.752V34.6272C918.832 34.0035 918.689 33.3716 918.35 32.8426C918.214 32.7099 918.052 32.6066 917.875 32.5392C917.697 32.4717 917.508 32.4416 917.318 32.4506C916.876 32.4415 916.446 32.5958 916.111 32.8839C915.75 33.2805 915.557 33.8022 915.575 34.3384V39.2591H912.294L912.273 30.0882Z" fill="#2F2A1B"/>
      <path id="Vector_86" d="M932.678 32.7807V39.2591H929.398V32.7807H928.366V30.0883H929.398V27.076H932.678V30.0883H934.546V32.7807H932.678Z" fill="#2F2A1B"/>
      <path id="Vector_87" d="M945.006 38.0522C944.459 38.5747 943.81 38.9789 943.1 39.2398C942.391 39.5007 941.635 39.6126 940.879 39.5686C940.124 39.6139 939.368 39.5026 938.658 39.2416C937.948 38.9806 937.299 38.5757 936.753 38.0522C936.296 37.6167 935.935 37.0911 935.691 36.5087C935.448 35.9262 935.328 35.2996 935.34 34.6685C935.328 34.0405 935.448 33.4169 935.691 32.8377C935.935 32.2586 936.296 31.7367 936.753 31.3056C937.306 30.792 937.956 30.3934 938.664 30.1331C939.372 29.8727 940.126 29.7559 940.879 29.7891C942.401 29.7299 943.885 30.275 945.006 31.3056C945.463 31.7367 945.824 32.2586 946.068 32.8377C946.311 33.4169 946.431 34.0405 946.419 34.6685C946.431 35.2996 946.311 35.9262 946.068 36.5087C945.824 37.0911 945.463 37.6167 945.006 38.0522V38.0522ZM939.446 33.1728C939.247 33.3684 939.09 33.6012 938.982 33.8579C938.874 34.1146 938.818 34.3901 938.816 34.6685C938.835 35.2155 939.06 35.7351 939.446 36.1231C939.638 36.3193 939.867 36.4751 940.121 36.5814C940.374 36.6877 940.646 36.7423 940.921 36.742C941.194 36.7409 941.464 36.6856 941.715 36.5794C941.967 36.4731 942.195 36.318 942.386 36.1231C942.581 35.9341 942.738 35.7083 942.845 35.4586C942.953 35.209 943.011 34.9405 943.015 34.6685C943.001 34.1086 942.776 33.5745 942.386 33.1728C941.985 32.804 941.465 32.5917 940.921 32.5744C940.373 32.5901 939.849 32.8025 939.446 33.1728V33.1728Z" fill="#2F2A1B"/>
      <path id="Vector_88" d="M960.016 39.0528C959.313 39.4076 958.533 39.5847 957.746 39.5686C956.406 39.5994 955.108 39.0988 954.135 38.1759C953.683 37.7281 953.324 37.1947 953.079 36.6067C952.835 36.0187 952.71 35.3879 952.712 34.7511C952.71 34.0904 952.841 33.436 953.096 32.8266C953.351 32.2171 953.726 31.6649 954.197 31.2024C955.197 30.2762 956.518 29.7766 957.88 29.8098C958.622 29.8048 959.355 29.9781 960.016 30.3152V33.0696C959.527 32.6935 958.93 32.4838 958.313 32.4713C958.017 32.4623 957.722 32.5144 957.447 32.6245C957.172 32.7345 956.923 32.9001 956.714 33.1108C956.509 33.3185 956.346 33.5649 956.236 33.8359C956.127 34.1068 956.072 34.3969 956.075 34.6892C956.077 35.2542 956.282 35.7995 956.653 36.2263C956.869 36.4532 957.13 36.632 957.42 36.7511C957.709 36.8702 958.021 36.9269 958.334 36.9174C958.951 36.9341 959.552 36.7167 960.016 36.3088V39.0528Z" fill="#2F2A1B"/>
      <path id="Vector_89" d="M961.924 24.3113H965.204V31.2436C965.494 30.8841 965.843 30.577 966.236 30.3358C966.811 30.025 967.46 29.8753 968.113 29.9026C968.608 29.8737 969.104 29.9457 969.571 30.1142C970.037 30.2826 970.464 30.5441 970.827 30.8826C971.147 31.2209 971.39 31.6251 971.538 32.067C971.686 32.509 971.735 32.9778 971.683 33.4409V39.2694H968.402V34.6375C968.474 34.0143 968.332 33.3852 968 32.8529C967.867 32.7164 967.706 32.6107 967.527 32.543C967.349 32.4753 967.158 32.4473 966.968 32.4609C966.538 32.4405 966.115 32.5801 965.782 32.8529C965.59 33.0499 965.442 33.2847 965.346 33.5422C965.25 33.7997 965.209 34.0744 965.225 34.3487V39.2694H961.924V24.3113Z" fill="#2F2A1B"/>
      <path id="Vector_90" d="M983.195 35.2772H976.366C976.364 35.8442 976.566 36.3931 976.934 36.8245C977.313 37.1436 977.801 37.3027 978.295 37.2682C978.653 37.2583 979.004 37.174 979.327 37.0206C979.573 36.8765 979.774 36.6654 979.905 36.4119H982.999C982.805 37.1301 982.42 37.7824 981.885 38.2997C981.383 38.7524 980.794 39.0983 980.153 39.3164C979.513 39.5345 978.835 39.6203 978.161 39.5686C977.467 39.6041 976.773 39.4991 976.121 39.2598C975.469 39.0205 974.871 38.6518 974.365 38.1759C973.92 37.7173 973.572 37.1744 973.339 36.5792C973.107 35.984 972.997 35.3484 973.014 34.7098C972.992 34.0565 973.103 33.4056 973.339 32.7959C973.574 32.1862 973.93 31.6303 974.386 31.1611C974.892 30.7014 975.485 30.347 976.129 30.1186C976.774 29.8901 977.458 29.7922 978.14 29.8304C979.434 29.7744 980.698 30.2218 981.669 31.0786C982.163 31.5871 982.548 32.1922 982.798 32.856C983.048 33.5199 983.159 34.2282 983.123 34.9368L983.195 35.2772ZM980.173 33.3894C980.088 33.0216 979.906 32.6832 979.647 32.4093C979.277 32.0752 978.793 31.8977 978.295 31.9142C978.05 31.8992 977.803 31.9355 977.573 32.0207C977.342 32.106 977.131 32.2383 976.954 32.4093C976.699 32.6807 976.53 33.0218 976.469 33.3894H980.173Z" fill="#2F2A1B"/>
      <path id="Vector_91" d="M992.015 39.0528C991.309 39.4076 990.526 39.5847 989.736 39.5685C988.396 39.5971 987.099 39.0968 986.125 38.1759C985.674 37.7276 985.316 37.1938 985.074 36.6057C984.831 36.0177 984.708 35.3872 984.712 34.751C984.709 34.0911 984.838 33.4371 985.091 32.8277C985.344 32.2183 985.717 31.6657 986.187 31.2024C987.191 30.2765 988.515 29.7771 989.88 29.8097C990.622 29.8059 991.354 29.9792 992.015 30.3152V33.0695C991.525 32.6966 990.929 32.4873 990.313 32.4712C990.015 32.4614 989.719 32.5131 989.442 32.6232C989.165 32.7332 988.914 32.8992 988.704 33.1108C988.295 33.5337 988.069 34.1008 988.075 34.6891C988.077 35.2541 988.282 35.7994 988.652 36.2262C988.867 36.4547 989.128 36.6346 989.418 36.7538C989.708 36.8731 990.021 36.9288 990.334 36.9174C990.951 36.931 991.55 36.714 992.015 36.3087V39.0528Z" fill="#2F2A1B"/>
      <path id="Vector_92" d="M997.163 24.3113V33.3893L1000.26 30.0882H1004.7L1000.38 34.3178L1005.05 39.2591H1000.51L997.163 35.5041V39.2591H993.903V24.3113H997.163Z" fill="#2F2A1B"/>
      <path id="Vector_93" d="M1020.21 38.0522C1019.66 38.5752 1019.01 38.9798 1018.3 39.2407C1017.59 39.5016 1016.84 39.6133 1016.08 39.5686C1015.33 39.6133 1014.57 39.5016 1013.86 39.2407C1013.15 38.9798 1012.5 38.5752 1011.96 38.0522C1011.5 37.6167 1011.14 37.0911 1010.89 36.5087C1010.65 35.9262 1010.53 35.2996 1010.54 34.6685C1010.53 34.0405 1010.65 33.4169 1010.89 32.8377C1011.14 32.2586 1011.5 31.7367 1011.96 31.3056C1012.51 30.7924 1013.16 30.3942 1013.87 30.1339C1014.58 29.8736 1015.33 29.7564 1016.08 29.7891C1017.6 29.7288 1019.09 30.2741 1020.21 31.3056C1020.67 31.7367 1021.03 32.2586 1021.27 32.8377C1021.51 33.4169 1021.63 34.0405 1021.62 34.6685C1021.63 35.2996 1021.51 35.9262 1021.27 36.5087C1021.03 37.0911 1020.67 37.6167 1020.21 38.0522V38.0522ZM1014.65 33.1728C1014.45 33.3684 1014.29 33.6012 1014.18 33.8579C1014.08 34.1146 1014.02 34.3901 1014.02 34.6685C1014.04 35.2155 1014.26 35.7351 1014.65 36.1231C1014.84 36.3193 1015.07 36.4751 1015.32 36.5814C1015.58 36.6877 1015.85 36.7423 1016.12 36.7421C1016.4 36.7409 1016.67 36.6856 1016.92 36.5794C1017.17 36.4731 1017.4 36.318 1017.59 36.1231C1017.78 35.9341 1017.94 35.7083 1018.05 35.4586C1018.16 35.209 1018.21 34.9405 1018.22 34.6685C1018.2 34.1086 1017.98 33.5745 1017.59 33.1728C1017.19 32.804 1016.67 32.5917 1016.12 32.5744C1015.58 32.5901 1015.05 32.8025 1014.65 33.1728V33.1728Z" fill="#2F2A1B"/>
      <path id="Vector_94" d="M1026.79 30.0883V35.2462C1026.77 35.4919 1026.81 35.7382 1026.9 35.9692C1026.98 36.2001 1027.11 36.4107 1027.29 36.5873C1027.61 36.838 1028.02 36.9592 1028.43 36.9277C1028.64 36.949 1028.86 36.9261 1029.06 36.8603C1029.26 36.7946 1029.45 36.6876 1029.61 36.546C1029.77 36.3724 1029.89 36.1675 1029.97 35.944C1030.05 35.7205 1030.09 35.4831 1030.07 35.2462V30.0883H1033.35V35.8136C1033.39 36.2633 1033.33 36.7159 1033.18 37.1412C1033.03 37.5664 1032.79 37.9543 1032.47 38.2791C1031.94 38.7529 1031.31 39.1118 1030.63 39.3335C1029.95 39.5553 1029.23 39.6353 1028.51 39.5686C1027.77 39.6433 1027.02 39.5656 1026.31 39.3402C1025.6 39.1149 1024.95 38.7468 1024.39 38.2585C1024.08 37.9309 1023.85 37.5453 1023.7 37.125C1023.55 36.7047 1023.49 36.2586 1023.52 35.8136V30.0883H1026.79Z" fill="#2F2A1B"/>
      <path id="Vector_95" d="M1039.29 32.7807V39.2591H1036.01V32.7807H1034.98V30.0883H1036.01V27.076H1039.29V30.0883H1041.16V32.7807H1039.29Z" fill="#2F2A1B"/>
      <path id="Vector_96" d="M1047.11 30.0882H1050.39V31.2024C1050.73 30.7975 1051.15 30.4726 1051.63 30.2513C1052.1 30.0299 1052.62 29.9179 1053.15 29.9232C1053.76 29.9143 1054.37 30.056 1054.91 30.3358C1055.46 30.627 1055.91 31.0858 1056.18 31.646C1056.47 31.1176 1056.91 30.679 1057.43 30.3771C1058.01 30.0579 1058.67 29.9012 1059.34 29.9232C1060.26 29.8703 1061.17 30.1729 1061.88 30.7691C1062.19 31.1107 1062.42 31.5107 1062.57 31.9451C1062.72 32.3795 1062.78 32.8392 1062.74 33.2965V39.2591H1059.46V34.7304C1059.56 34.037 1059.39 33.3309 1058.99 32.7601C1058.87 32.6532 1058.74 32.5706 1058.59 32.5174C1058.44 32.4642 1058.29 32.4415 1058.13 32.4506C1057.95 32.444 1057.78 32.4735 1057.61 32.5373C1057.45 32.6012 1057.3 32.698 1057.17 32.822C1056.96 33.0566 1056.79 33.3323 1056.69 33.632C1056.59 33.9318 1056.55 34.2493 1056.57 34.5653V39.2591H1053.29V34.7304C1053.39 34.043 1053.23 33.3445 1052.86 32.7601C1052.73 32.625 1052.56 32.5215 1052.38 32.4572C1052.21 32.3929 1052.02 32.3695 1051.83 32.3887C1051.66 32.3756 1051.49 32.3983 1051.34 32.4553C1051.18 32.5122 1051.04 32.6021 1050.92 32.7188C1050.69 32.9989 1050.53 33.321 1050.43 33.6664C1050.33 34.0118 1050.3 34.3735 1050.34 34.7304V39.2591H1047.06L1047.11 30.0882Z" fill="#2F2A1B"/>
      <path id="Vector_97" d="M1068.32 38.0109L1063.89 30.0883H1067.7L1070.15 34.7923L1072.52 30.0883H1076.28L1068.92 43.9528H1065.25L1068.32 38.0109Z" fill="#2F2A1B"/>
      <path id="Vector_98" d="M1085.47 43.9528H1082.19V30.0882H1085.47V31.1198C1085.8 30.6958 1086.23 30.3561 1086.71 30.1284C1087.2 29.9007 1087.74 29.7915 1088.27 29.8097C1088.88 29.8027 1089.48 29.9168 1090.04 30.1453C1090.6 30.3739 1091.11 30.7123 1091.53 31.1405C1091.99 31.6095 1092.35 32.1647 1092.59 32.7739C1092.83 33.3831 1092.95 34.0342 1092.94 34.6892C1092.95 35.3636 1092.82 36.0335 1092.57 36.6578C1092.31 37.2821 1091.93 37.8478 1091.45 38.3203C1090.63 39.1347 1089.51 39.5907 1088.36 39.5892C1087.83 39.5949 1087.3 39.4926 1086.81 39.2886C1086.32 39.0847 1085.88 38.7833 1085.51 38.4029L1085.47 43.9528ZM1086 33.1933C1085.6 33.5975 1085.37 34.1407 1085.36 34.7098C1085.39 35.257 1085.62 35.7751 1086 36.1643C1086.2 36.3605 1086.43 36.5163 1086.68 36.6226C1086.93 36.7289 1087.2 36.7835 1087.48 36.7833C1087.75 36.7855 1088.02 36.7317 1088.27 36.6252C1088.52 36.5187 1088.75 36.3618 1088.93 36.1643C1089.32 35.7774 1089.55 35.2579 1089.57 34.7098C1089.56 34.1407 1089.33 33.5975 1088.93 33.1933C1088.75 32.9958 1088.52 32.8389 1088.27 32.7324C1088.02 32.626 1087.75 32.5722 1087.48 32.5744C1087.2 32.5741 1086.93 32.6288 1086.68 32.7351C1086.43 32.8413 1086.2 32.9972 1086 33.1933V33.1933Z" fill="#2F2A1B"/>
      <path id="Vector_99" d="M1104.05 38.0522C1103.5 38.5752 1102.85 38.9798 1102.14 39.2407C1101.43 39.5016 1100.67 39.6133 1099.92 39.5686C1099.16 39.6133 1098.41 39.5016 1097.7 39.2407C1096.99 38.9798 1096.34 38.5752 1095.79 38.0522C1095.34 37.6167 1094.97 37.0911 1094.73 36.5087C1094.49 35.9262 1094.37 35.2996 1094.38 34.6685C1094.37 34.0405 1094.49 33.4169 1094.73 32.8377C1094.97 32.2586 1095.34 31.7367 1095.79 31.3056C1096.35 30.7924 1097 30.3942 1097.7 30.1339C1098.41 29.8736 1099.17 29.7564 1099.92 29.7891C1101.44 29.7288 1102.93 30.2741 1104.05 31.3056C1104.5 31.7367 1104.86 32.2586 1105.11 32.8377C1105.35 33.4169 1105.47 34.0405 1105.46 34.6685C1105.47 35.2996 1105.35 35.9262 1105.11 36.5087C1104.86 37.0911 1104.5 37.6167 1104.05 38.0522V38.0522ZM1098.49 33.1728C1098.29 33.3684 1098.13 33.6012 1098.02 33.8579C1097.91 34.1146 1097.86 34.3901 1097.86 34.6685C1097.88 35.2155 1098.1 35.7351 1098.49 36.1231C1098.68 36.3205 1098.91 36.4771 1099.17 36.5834C1099.42 36.6897 1099.69 36.7437 1099.97 36.7421C1100.24 36.7396 1100.51 36.6836 1100.76 36.5774C1101.01 36.4712 1101.24 36.3168 1101.43 36.1231C1101.62 35.9341 1101.78 35.7083 1101.89 35.4586C1101.99 35.209 1102.05 34.9405 1102.06 34.6685C1102.04 34.1086 1101.82 33.5745 1101.43 33.1728C1101.03 32.8063 1100.51 32.5941 1099.97 32.5744C1099.42 32.5877 1098.89 32.8002 1098.49 33.1728V33.1728Z" fill="#2F2A1B"/>
      <path id="Vector_100" d="M1107.38 30.0883H1110.66V31.5944C1110.95 31.0423 1111.4 30.5948 1111.96 30.3152C1112.49 30.0511 1113.07 29.9234 1113.66 29.9439H1114.31V33.0386C1113.87 32.8106 1113.39 32.6969 1112.9 32.7085C1112.35 32.6769 1111.82 32.854 1111.4 33.2037C1111.14 33.4781 1110.94 33.8029 1110.81 34.1579C1110.69 34.5128 1110.63 34.8904 1110.66 35.2669V39.1869H1107.38V30.0883Z" fill="#2F2A1B"/>
      <path id="Vector_101" d="M1119.67 32.7807V39.2591H1116.39V32.7807H1115.36V30.0883H1116.39V27.076H1119.67V30.0883H1121.54V32.7807H1119.67Z" fill="#2F2A1B"/>
      <path id="Vector_102" d="M1127.12 32.7807V39.2591H1123.88V32.7807H1122.73V30.0883H1123.88V28.5512C1123.81 27.481 1124.1 26.4173 1124.72 25.5389C1125.1 25.0617 1125.58 24.6808 1126.13 24.4279C1126.68 24.175 1127.29 24.0572 1127.9 24.0844C1128.44 24.083 1128.97 24.1882 1129.47 24.3939V27.2204C1129.16 27.0278 1128.81 26.921 1128.44 26.911C1128.27 26.8989 1128.1 26.924 1127.94 26.9846C1127.78 27.0451 1127.63 27.1397 1127.51 27.2617C1127.23 27.7206 1127.1 28.2607 1127.15 28.7988V30.0883H1129.44V32.7807H1127.12Z" fill="#2F2A1B"/>
      <path id="Vector_103" d="M1139.89 38.0522C1139.35 38.5752 1138.7 38.9798 1137.99 39.2407C1137.28 39.5016 1136.52 39.6133 1135.77 39.5686C1135.01 39.6133 1134.26 39.5016 1133.55 39.2407C1132.84 38.9798 1132.19 38.5752 1131.64 38.0522C1131.18 37.6167 1130.82 37.0911 1130.58 36.5087C1130.34 35.9262 1130.22 35.2996 1130.23 34.6685C1130.22 34.0405 1130.34 33.4169 1130.58 32.8377C1130.82 32.2586 1131.18 31.7367 1131.64 31.3056C1132.19 30.7924 1132.84 30.3942 1133.55 30.1339C1134.26 29.8736 1135.01 29.7564 1135.77 29.7891C1137.29 29.7288 1138.77 30.2741 1139.89 31.3056C1140.35 31.7367 1140.71 32.2586 1140.96 32.8377C1141.2 33.4169 1141.32 34.0405 1141.31 34.6685C1141.32 35.2996 1141.2 35.9262 1140.96 36.5087C1140.71 37.0911 1140.35 37.6167 1139.89 38.0522V38.0522ZM1134.33 33.1728C1134.14 33.3684 1133.98 33.6012 1133.87 33.8579C1133.76 34.1146 1133.71 34.3901 1133.7 34.6685C1133.72 35.2155 1133.95 35.7351 1134.33 36.1231C1134.53 36.3205 1134.76 36.4771 1135.01 36.5834C1135.27 36.6897 1135.54 36.7437 1135.82 36.7421C1136.09 36.7396 1136.36 36.6836 1136.61 36.5774C1136.86 36.4712 1137.08 36.3168 1137.27 36.1231C1137.47 35.9341 1137.63 35.7083 1137.73 35.4586C1137.84 35.209 1137.9 34.9405 1137.9 34.6685C1137.89 34.1077 1137.67 33.5722 1137.27 33.1728C1136.88 32.8063 1136.36 32.5941 1135.82 32.5744C1135.27 32.5877 1134.74 32.8002 1134.33 33.1728V33.1728Z" fill="#2F2A1B"/>
      <path id="Vector_104" d="M1146.48 24.3113V39.2591H1143.19V24.3113H1146.48Z" fill="#2F2A1B"/>
      <path id="Vector_105" d="M1150.35 24.4351C1150.6 24.4338 1150.84 24.4806 1151.06 24.5729C1151.29 24.6652 1151.49 24.8012 1151.66 24.9729C1151.83 25.1447 1151.97 25.3488 1152.06 25.5734C1152.16 25.7981 1152.2 26.0388 1152.2 26.2817C1152.2 26.7714 1152.01 27.2411 1151.66 27.5874C1151.31 27.9337 1150.84 28.1282 1150.35 28.1282C1149.86 28.1282 1149.39 27.9337 1149.05 27.5874C1148.7 27.2411 1148.51 26.7714 1148.51 26.2817C1148.51 26.0388 1148.55 25.7981 1148.65 25.5734C1148.74 25.3488 1148.87 25.1447 1149.05 24.9729C1149.22 24.8012 1149.42 24.6652 1149.65 24.5729C1149.87 24.4806 1150.11 24.4338 1150.35 24.4351V24.4351ZM1151.99 30.0882V39.2591H1148.71V30.0882H1151.99Z" fill="#2F2A1B"/>
      <path id="Vector_106" d="M1163.46 38.0521C1162.91 38.5751 1162.26 38.9797 1161.55 39.2407C1160.84 39.5016 1160.08 39.6132 1159.33 39.5686C1158.57 39.612 1157.82 39.4998 1157.11 39.2389C1156.4 38.9781 1155.75 38.5741 1155.2 38.0521C1154.75 37.6151 1154.39 37.0893 1154.14 36.5072C1153.9 35.9251 1153.78 35.2993 1153.79 34.6685C1153.78 34.0407 1153.9 33.4177 1154.14 32.8388C1154.39 32.2599 1154.75 31.7378 1155.2 31.3055C1155.76 30.7933 1156.41 30.3957 1157.11 30.1355C1157.82 29.8752 1158.58 29.7575 1159.33 29.7891C1160.08 29.7581 1160.84 29.8761 1161.54 30.1363C1162.25 30.3965 1162.9 30.7938 1163.46 31.3055C1163.91 31.7357 1164.28 32.2572 1164.52 32.8364C1164.77 33.4156 1164.89 34.0397 1164.88 34.6685C1164.89 35.3003 1164.77 35.9272 1164.52 36.5096C1164.28 37.0919 1163.91 37.6172 1163.46 38.0521V38.0521ZM1157.91 33.1727C1157.7 33.3657 1157.54 33.5978 1157.43 33.8549C1157.32 34.112 1157.27 34.3888 1157.27 34.6685C1157.3 35.2099 1157.53 35.7192 1157.93 36.0921C1158.12 36.2883 1158.35 36.4441 1158.6 36.5504C1158.85 36.6567 1159.13 36.7113 1159.4 36.7111C1159.67 36.7086 1159.94 36.6526 1160.19 36.5464C1160.44 36.4402 1160.67 36.2858 1160.86 36.0921C1161.05 35.904 1161.21 35.6785 1161.32 35.4289C1161.43 35.1792 1161.49 34.9102 1161.5 34.6376C1161.48 34.0755 1161.25 33.5402 1160.86 33.1418C1160.46 32.7738 1159.94 32.5613 1159.4 32.5434C1158.85 32.5638 1158.32 32.788 1157.93 33.1727H1157.91Z" fill="#2F2A1B"/>
      <path id="Vector_107" d="M1169.41 34.5241V31.2024C1169.63 31.2692 1169.86 31.3039 1170.1 31.3056C1170.8 31.3315 1171.49 31.0882 1172.03 30.6247C1172.33 30.3261 1172.5 29.9226 1172.5 29.5003C1172.51 29.1442 1172.39 28.7976 1172.15 28.5306C1172.02 28.3953 1171.86 28.2902 1171.68 28.2226C1171.5 28.155 1171.31 28.1263 1171.12 28.1386C1170.96 28.1318 1170.79 28.1595 1170.64 28.2199C1170.49 28.2804 1170.35 28.3721 1170.23 28.4893C1169.99 28.7774 1169.86 29.1444 1169.87 29.5209H1166.26C1166.29 28.4293 1166.73 27.3888 1167.49 26.6015C1167.97 26.1555 1168.53 25.8084 1169.14 25.58C1169.75 25.3516 1170.4 25.2465 1171.06 25.2708C1172.4 25.2266 1173.7 25.7038 1174.69 26.6015C1175.09 26.9772 1175.42 27.432 1175.64 27.9377C1175.86 28.4434 1175.98 28.9893 1175.98 29.5415C1175.96 30.4973 1175.59 31.413 1174.95 32.1205C1174.34 32.7882 1173.53 33.2466 1172.65 33.4307V34.5241H1169.41ZM1169.68 38.929C1169.47 38.7361 1169.31 38.5039 1169.2 38.2468C1169.09 37.9897 1169.04 37.7129 1169.04 37.4332C1169.04 37.1535 1169.09 36.8768 1169.2 36.6196C1169.31 36.3625 1169.47 36.1304 1169.68 35.9374C1169.87 35.7375 1170.1 35.5779 1170.35 35.468C1170.6 35.3581 1170.87 35.3003 1171.15 35.2978C1171.44 35.2999 1171.72 35.3594 1171.99 35.4728C1172.25 35.5862 1172.49 35.7511 1172.69 35.958C1172.88 36.1488 1173.04 36.3785 1173.14 36.6325C1173.25 36.8865 1173.29 37.1592 1173.29 37.4332C1173.3 37.7122 1173.25 37.99 1173.14 38.248C1173.03 38.506 1172.87 38.7382 1172.67 38.929C1172.47 39.1314 1172.24 39.2925 1171.99 39.4025C1171.73 39.5124 1171.45 39.5689 1171.17 39.5686C1170.89 39.5689 1170.62 39.5124 1170.36 39.4025C1170.1 39.2925 1169.87 39.1314 1169.68 38.929Z" fill="#2F2A1B"/>
      </g>
      <g id="bubble">
      <path id="Vector_108" d="M772.26 5H739C731.839 5 724.972 7.84466 719.908 12.9081C714.845 17.9716 712 24.8392 712 32V32C712.019 38.9371 714.707 45.6009 719.507 50.6093C724.307 55.6178 730.85 58.5866 737.78 58.9C736.24 62.66 733.36 69.03 730.41 72.44C726.97 76.44 757.95 83.23 766.41 58.93H772.19C775.736 58.93 779.247 58.2316 782.522 56.8748C785.798 55.5179 788.775 53.5291 791.282 51.0219C793.789 48.5147 795.778 45.5383 797.135 42.2625C798.492 38.9867 799.19 35.4757 799.19 31.93V31.93C799.172 24.7934 796.328 17.9544 791.282 12.9081C786.236 7.8617 779.397 5.01848 772.26 5V5Z" fill="#E7EFFB" stroke="#DDE8F6" stroke-width="8" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="Vector_109" d="M731.21 23.71H734.39V30.43C734.673 30.0864 735.011 29.7924 735.39 29.56C735.95 29.2532 736.582 29.1013 737.22 29.12C737.7 29.0944 738.18 29.1652 738.631 29.3284C739.083 29.4916 739.497 29.7438 739.85 30.07C740.161 30.3998 740.396 30.7935 740.54 31.2235C740.683 31.6535 740.731 32.1096 740.68 32.56V38.21H737.5V33.71C737.57 33.1045 737.428 32.4935 737.1 31.98C736.97 31.849 736.814 31.7479 736.641 31.684C736.468 31.6201 736.284 31.5948 736.1 31.61C735.684 31.5893 735.276 31.7209 734.95 31.98C734.765 32.1717 734.622 32.3994 734.529 32.6488C734.436 32.8983 734.396 33.1642 734.41 33.43V38.21H731.23L731.21 23.71Z" fill="#2F2A1B"/>
      <path id="Vector_110" d="M751.87 34.35H745.24C745.237 34.8987 745.436 35.4292 745.8 35.84C745.978 35.9965 746.186 36.116 746.411 36.1915C746.636 36.2671 746.873 36.2972 747.11 36.28C747.456 36.2696 747.797 36.1879 748.11 36.04C748.348 35.8984 748.54 35.6897 748.66 35.44H751.66C751.472 36.1362 751.098 36.7685 750.58 37.27C750.093 37.7116 749.52 38.0486 748.897 38.2601C748.275 38.4717 747.615 38.5533 746.96 38.5C746.289 38.5331 745.618 38.4306 744.988 38.1987C744.357 37.9667 743.78 37.6101 743.29 37.15C742.856 36.7069 742.516 36.1812 742.289 35.604C742.062 35.0268 741.954 34.4099 741.97 33.79C741.952 33.1561 742.061 32.5251 742.291 31.9342C742.522 31.3433 742.868 30.8046 743.31 30.35C743.8 29.9056 744.373 29.5628 744.996 29.3414C745.619 29.1199 746.28 29.0243 746.94 29.06C748.193 29.0079 749.418 29.4413 750.36 30.27C750.841 30.7621 751.216 31.3483 751.46 31.9918C751.705 32.6354 751.814 33.3224 751.78 34.01L751.87 34.35ZM748.94 32.52C748.854 32.165 748.678 31.838 748.43 31.57C748.07 31.2436 747.596 31.0714 747.11 31.09C746.873 31.0763 746.637 31.1119 746.415 31.1945C746.192 31.2771 745.99 31.405 745.82 31.57C745.566 31.8295 745.398 32.1614 745.34 32.52H748.94Z" fill="#2F2A1B"/>
      <path id="Vector_111" d="M756.87 23.71V38.21H753.69V23.71H756.87Z" fill="#2F2A1B"/>
      <path id="Vector_112" d="M762.22 23.71V38.21H759.04V23.71H762.22Z" fill="#2F2A1B"/>
      <path id="Vector_113" d="M773.34 37.03C772.811 37.5375 772.182 37.93 771.494 38.183C770.805 38.436 770.072 38.5439 769.34 38.5C768.608 38.5439 767.875 38.436 767.186 38.183C766.498 37.93 765.87 37.5375 765.34 37.03C764.898 36.6075 764.547 36.0979 764.312 35.5334C764.076 34.9689 763.96 34.3616 763.97 33.75C763.962 33.1415 764.079 32.538 764.315 31.977C764.551 31.4161 764.9 30.9098 765.34 30.49C766.427 29.491 767.865 28.9626 769.34 29.02C770.814 28.9701 772.249 29.4976 773.34 30.49C773.781 30.9098 774.13 31.4161 774.365 31.977C774.601 32.538 774.718 33.1415 774.71 33.75C774.721 34.3616 774.604 34.9689 774.368 35.5334C774.133 36.0979 773.783 36.6075 773.34 37.03ZM767.95 32.3C767.757 32.4891 767.602 32.7145 767.496 32.9633C767.389 33.2121 767.333 33.4794 767.33 33.75C767.335 34.0143 767.393 34.275 767.499 34.517C767.605 34.7591 767.759 34.9776 767.95 35.16C768.137 35.3501 768.359 35.5012 768.605 35.6042C768.85 35.7072 769.114 35.7602 769.38 35.76C769.643 35.7576 769.903 35.7033 770.145 35.6004C770.387 35.4974 770.606 35.3477 770.79 35.16C770.985 34.9801 771.14 34.7621 771.247 34.5195C771.354 34.2769 771.409 34.015 771.41 33.75C771.393 33.206 771.171 32.6885 770.79 32.3C770.405 31.9432 769.905 31.7373 769.38 31.72C768.849 31.7334 768.341 31.9395 767.95 32.3V32.3Z" fill="#2F2A1B"/>
      <path id="Vector_114" d="M777.39 37.97C777.196 37.7821 777.041 37.5567 776.937 37.3075C776.832 37.0583 776.778 36.7904 776.78 36.52C776.777 36.248 776.83 35.9782 776.934 35.7271C777.039 35.4761 777.194 35.2491 777.39 35.06C777.578 34.8656 777.803 34.7113 778.052 34.6065C778.302 34.5016 778.57 34.4484 778.84 34.45C779.394 34.4568 779.923 34.6834 780.31 35.08C780.502 35.2664 780.652 35.4908 780.752 35.7388C780.852 35.9867 780.899 36.2528 780.89 36.52C780.897 36.7903 780.847 37.059 780.744 37.3087C780.641 37.5585 780.486 37.7838 780.29 37.97C780.102 38.1644 779.877 38.3187 779.628 38.4236C779.378 38.5284 779.11 38.5816 778.84 38.58C778.57 38.5816 778.302 38.5284 778.052 38.4236C777.803 38.3187 777.578 38.1644 777.39 37.97ZM777.17 33.69V24.95H780.47V33.69H777.17Z" fill="#2F2A1B"/>
      </g>
      <g id="say-hi">
      <path id="Vector_115" d="M541.841 7.33999V10.25C541.468 9.75813 541.015 9.33225 540.501 8.98999C540.084 8.75488 539.61 8.63738 539.131 8.64999C538.748 8.63831 538.367 8.70904 538.013 8.8574C537.659 9.00577 537.342 9.22831 537.081 9.50999C536.809 9.79796 536.596 10.1371 536.455 10.5079C536.314 10.8786 536.248 11.2736 536.261 11.67C536.233 12.4744 536.516 13.2587 537.051 13.86C537.306 14.135 537.615 14.3529 537.96 14.4994C538.305 14.6459 538.677 14.7177 539.051 14.71C539.536 14.7213 540.016 14.604 540.441 14.37C540.968 14.0257 541.432 13.5926 541.811 13.09V15.97C540.993 16.4112 540.081 16.648 539.151 16.66C538.477 16.68 537.805 16.5657 537.175 16.3236C536.545 16.0816 535.969 15.7166 535.481 15.25C535 14.7945 534.62 14.2433 534.365 13.6318C534.11 13.0204 533.986 12.3623 534.001 11.7C533.988 11.0332 534.115 10.3711 534.374 9.7563C534.632 9.14147 535.016 8.58749 535.501 8.12999C535.983 7.65253 536.557 7.27716 537.188 7.02631C537.818 6.77546 538.493 6.65428 539.171 6.66999C540.103 6.66642 541.021 6.89679 541.841 7.33999V7.33999Z" fill="#2F2A1B"/>
      <path id="Vector_116" d="M546.141 0V16.39H543.951V0H546.141Z" fill="#2F2A1B"/>
      <path id="Vector_117" d="M548.571 3.00999C548.566 2.82307 548.601 2.63726 548.674 2.46486C548.746 2.29246 548.854 2.13742 548.991 2.00999C549.12 1.87513 549.276 1.76825 549.448 1.696C549.62 1.62374 549.805 1.58766 549.991 1.59C550.178 1.58883 550.362 1.62545 550.534 1.69763C550.706 1.76982 550.862 1.87608 550.991 2.00999C551.128 2.13782 551.235 2.29294 551.308 2.46524C551.38 2.63754 551.415 2.82316 551.411 3.00999C551.415 3.19675 551.379 3.38217 551.307 3.55438C551.235 3.72659 551.127 3.88179 550.991 4.00999C550.863 4.14583 550.708 4.25337 550.536 4.3257C550.363 4.39803 550.178 4.43355 549.991 4.42999C549.805 4.43174 549.62 4.39539 549.448 4.32317C549.276 4.25095 549.121 4.14438 548.991 4.00999C548.856 3.88141 548.749 3.72615 548.676 3.55403C548.604 3.38191 548.568 3.19666 548.571 3.00999V3.00999ZM551.091 6.93999V16.39H548.901V6.93999H551.091Z" fill="#2F2A1B"/>
      <path id="Vector_118" d="M561.211 7.34001V10.25C560.841 9.75579 560.387 9.3295 559.871 8.99001C559.454 8.75395 558.98 8.63638 558.501 8.65C558.126 8.64419 557.754 8.7178 557.409 8.86602C557.065 9.01424 556.755 9.23371 556.501 9.51001C556.229 9.79797 556.016 10.1371 555.875 10.5079C555.734 10.8787 555.668 11.2736 555.681 11.67C555.649 12.4751 555.932 13.2609 556.471 13.86C556.725 14.1355 557.035 14.3538 557.38 14.5003C557.725 14.6469 558.097 14.7184 558.471 14.71C558.953 14.721 559.429 14.6036 559.851 14.37C560.38 14.0283 560.844 13.5948 561.221 13.09V15.97C560.404 16.4135 559.491 16.6504 558.561 16.66C557.887 16.6784 557.215 16.5633 556.585 16.3213C555.956 16.0793 555.38 15.7153 554.891 15.25C554.413 14.7925 554.036 14.2407 553.783 13.6296C553.53 13.0184 553.407 12.3613 553.421 11.7C553.409 11.0342 553.535 10.3731 553.791 9.75862C554.048 9.14409 554.429 8.58952 554.911 8.13001C555.394 7.65347 555.968 7.27871 556.598 7.02793C557.229 6.77714 557.903 6.65543 558.581 6.67001C559.5 6.67308 560.403 6.90324 561.211 7.34001V7.34001Z" fill="#2F2A1B"/>
      <path id="Vector_119" d="M566.031 0V9.99999L569.101 6.95H572.041L567.931 10.95L572.341 16.43H569.501L566.381 12.43L566.031 12.78V16.42H563.841V0.0299988L566.031 0Z" fill="#2F2A1B"/>
      <path id="Vector_120" d="M579.431 11.6C579.421 10.9481 579.546 10.3012 579.799 9.7004C580.052 9.09957 580.428 8.55796 580.901 8.11C581.369 7.64187 581.926 7.27301 582.539 7.02556C583.153 6.77811 583.81 6.65717 584.471 6.67001C585.136 6.6609 585.796 6.78444 586.412 7.03341C587.029 7.28238 587.589 7.65179 588.061 8.12C588.534 8.58053 588.908 9.13315 589.159 9.74374C589.41 10.3543 589.533 11.0099 589.521 11.67C589.537 12.3344 589.414 12.9948 589.161 13.6093C588.908 14.2238 588.53 14.7791 588.051 15.24C587.573 15.7065 587.007 16.0724 586.385 16.3163C585.763 16.5602 585.099 16.6771 584.431 16.66C583.77 16.6767 583.112 16.5565 582.5 16.3069C581.887 16.0574 581.333 15.684 580.871 15.21C580.397 14.7387 580.025 14.1751 579.777 13.5542C579.529 12.9333 579.412 12.2682 579.431 11.6V11.6ZM581.671 11.6C581.627 12.4149 581.896 13.2158 582.421 13.84C582.685 14.1157 583.004 14.3323 583.357 14.4754C583.71 14.6185 584.09 14.6849 584.471 14.67C584.844 14.6815 585.214 14.6145 585.559 14.4732C585.903 14.3319 586.214 14.1196 586.471 13.85C587.004 13.2406 587.28 12.4485 587.241 11.64C587.281 10.8344 587.004 10.0452 586.471 9.44C586.215 9.16864 585.905 8.95443 585.56 8.8114C585.215 8.66837 584.844 8.59974 584.471 8.61C584.098 8.5991 583.727 8.66745 583.382 8.81052C583.037 8.95359 582.727 9.16813 582.471 9.44C582.197 9.73437 581.985 10.0808 581.848 10.4588C581.71 10.8367 581.65 11.2384 581.671 11.64V11.6Z" fill="#2F2A1B"/>
      <path id="Vector_121" d="M591.831 6.94H594.021V7.81C594.352 7.45563 594.751 7.17193 595.195 6.976C595.638 6.78007 596.116 6.67598 596.601 6.67C597.078 6.63864 597.556 6.71189 598.002 6.88462C598.447 7.05736 598.85 7.32541 599.181 7.67C599.785 8.5063 600.066 9.53287 599.971 10.56V16.35H597.781V11.07C597.841 10.4025 597.705 9.73199 597.391 9.14C597.223 8.94334 597.01 8.79114 596.769 8.69694C596.528 8.60274 596.268 8.56944 596.011 8.6C595.715 8.57218 595.416 8.62318 595.146 8.74786C594.875 8.87254 594.643 9.06647 594.471 9.31C594.109 10.0839 593.954 10.9383 594.021 11.79V16.35H591.831V6.94Z" fill="#2F2A1B"/>
      <path id="Vector_122" d="M608.871 6.94H611.051V7.81C611.361 7.45712 611.726 7.15658 612.131 6.92C612.502 6.74341 612.91 6.65772 613.321 6.67C613.829 6.66062 614.33 6.78507 614.774 7.03083C615.218 7.2766 615.59 7.63501 615.851 8.07C616.165 7.62451 616.583 7.26353 617.07 7.01925C617.557 6.77497 618.097 6.65499 618.641 6.67C620.771 6.67 621.831 7.97 621.831 10.55V16.39H619.641V11.14C619.691 10.4826 619.578 9.82302 619.311 9.22C619.195 9.03153 619.029 8.87871 618.831 8.77819C618.634 8.67768 618.412 8.63333 618.191 8.65C617.932 8.63024 617.673 8.68235 617.442 8.80057C617.211 8.91879 617.017 9.09854 616.881 9.32C616.558 10.0024 616.417 10.7569 616.471 11.51V16.39H614.271V11.17C614.271 9.49 613.791 8.65 612.821 8.65C612.556 8.63047 612.29 8.68342 612.052 8.80318C611.814 8.92294 611.614 9.10503 611.471 9.33C611.145 10.008 611 10.7592 611.051 11.51V16.39H608.871V6.94Z" fill="#2F2A1B"/>
      <path id="Vector_123" d="M633.201 12.18H626.431C626.449 12.8671 626.716 13.5244 627.181 14.03C627.405 14.2567 627.673 14.4343 627.97 14.5514C628.266 14.6685 628.583 14.7225 628.901 14.71C629.382 14.7289 629.855 14.5921 630.251 14.32C630.728 13.9166 631.131 13.4326 631.441 12.89L633.291 13.89C633.032 14.3312 632.73 14.7464 632.391 15.13C632.097 15.459 631.761 15.7481 631.391 15.99C631.023 16.2083 630.626 16.3732 630.211 16.48C629.762 16.5836 629.302 16.634 628.841 16.63C628.212 16.658 627.584 16.5511 626.999 16.3166C626.414 16.082 625.887 15.7252 625.451 15.27C624.56 14.2769 624.097 12.9726 624.161 11.64C624.105 10.3156 624.551 9.01879 625.411 8.00999C625.832 7.55475 626.347 7.19645 626.921 6.9599C627.494 6.72335 628.112 6.6142 628.731 6.64C629.345 6.61139 629.957 6.71539 630.526 6.94491C631.096 7.17444 631.609 7.52408 632.031 7.96999C632.881 8.99341 633.31 10.3019 633.231 11.63L633.201 12.18ZM630.961 10.39C630.876 9.8788 630.603 9.41766 630.196 9.09612C629.79 8.77458 629.278 8.61559 628.761 8.64999C628.506 8.64759 628.253 8.68816 628.011 8.77C627.778 8.84644 627.561 8.96508 627.371 9.11999C627.18 9.27292 627.015 9.45519 626.881 9.65999C626.744 9.88751 626.64 10.1333 626.571 10.39H630.961Z" fill="#2F2A1B"/>
      <path id="Vector_124" d="M643.981 8.98V16.39H641.791V8.98H640.861V6.98H641.791V3.48H643.981V6.95H645.671V8.95L643.981 8.98Z" fill="#2F2A1B"/>
      <path id="Vector_125" d="M646.851 11.6C646.841 10.9481 646.966 10.3012 647.219 9.7004C647.472 9.09957 647.848 8.55796 648.321 8.11C648.788 7.64046 649.345 7.27067 649.958 7.02312C650.572 6.77557 651.23 6.65543 651.891 6.67001C652.556 6.6609 653.216 6.78444 653.832 7.03341C654.449 7.28238 655.009 7.65179 655.481 8.12C655.954 8.58053 656.328 9.13315 656.579 9.74374C656.83 10.3543 656.953 11.0099 656.941 11.67C656.957 12.3344 656.834 12.9948 656.581 13.6093C656.328 14.2238 655.95 14.7791 655.471 15.24C654.993 15.7065 654.427 16.0724 653.805 16.3163C653.183 16.5602 652.519 16.6771 651.851 16.66C651.19 16.6779 650.532 16.5582 649.919 16.3086C649.306 16.059 648.752 15.6849 648.291 15.21C647.817 14.7387 647.445 14.1751 647.197 13.5542C646.949 12.9333 646.832 12.2682 646.851 11.6V11.6ZM649.081 11.6C649.04 12.4162 649.312 13.2173 649.841 13.84C650.099 14.1094 650.41 14.3223 650.754 14.4651C651.098 14.608 651.469 14.6778 651.841 14.67C652.213 14.6797 652.583 14.6118 652.928 14.4706C653.272 14.3294 653.583 14.1181 653.841 13.85C654.371 13.2396 654.644 12.4473 654.601 11.64C654.643 10.8358 654.371 10.0468 653.841 9.44C653.585 9.16864 653.275 8.95443 652.93 8.8114C652.585 8.66837 652.214 8.59974 651.841 8.61C651.468 8.59846 651.097 8.66651 650.752 8.80962C650.407 8.95273 650.097 9.16762 649.841 9.44C649.574 9.73759 649.37 10.0855 649.239 10.4633C649.109 10.8411 649.055 11.2412 649.081 11.64V11.6Z" fill="#2F2A1B"/>
      <path id="Vector_126" d="M670.881 8.55L669.071 9.55C668.791 8.97 668.431 8.68 668.011 8.68C667.823 8.67417 667.64 8.74238 667.501 8.87C667.432 8.9355 667.376 9.01454 667.339 9.10225C667.301 9.18996 667.281 9.28448 667.281 9.38C667.281 9.75 667.711 10.11 668.551 10.46C669.406 10.7794 670.198 11.2465 670.891 11.84C671.307 12.3055 671.523 12.9164 671.491 13.54C671.499 13.9712 671.414 14.3989 671.241 14.7941C671.068 15.1893 670.813 15.5426 670.491 15.83C669.838 16.4073 668.992 16.7179 668.121 16.7C667.385 16.7266 666.659 16.5153 666.052 16.0975C665.445 15.6796 664.989 15.0774 664.751 14.38L666.611 13.52C666.771 13.8328 666.973 14.1222 667.211 14.38C667.337 14.5016 667.486 14.5969 667.649 14.6604C667.812 14.7239 667.986 14.7544 668.161 14.75C668.901 14.75 669.271 14.42 669.271 13.75C669.271 13.36 668.981 13 668.411 12.67L667.751 12.34L667.081 12.02C666.578 11.8015 666.122 11.4854 665.741 11.09C665.4 10.659 665.226 10.1192 665.251 9.57C665.246 9.19676 665.318 8.82645 665.462 8.48229C665.607 8.13813 665.821 7.82754 666.091 7.57C666.66 7.03769 667.413 6.74722 668.191 6.76C668.767 6.7538 669.332 6.92123 669.812 7.24049C670.292 7.55975 670.664 8.01605 670.881 8.55V8.55Z" fill="#2F2A1B"/>
      <path id="Vector_127" d="M680.381 6.94001H682.571V16.39H680.381V15.39C679.613 16.1741 678.569 16.6264 677.471 16.65C676.861 16.6638 676.256 16.5448 675.696 16.3014C675.137 16.0579 674.637 15.6958 674.231 15.24C673.37 14.2383 672.917 12.95 672.961 11.63C672.918 10.3282 673.372 9.05871 674.231 8.08C674.627 7.6247 675.118 7.26162 675.669 7.0163C676.22 6.77097 676.818 6.64936 677.421 6.66001C677.989 6.66389 678.549 6.78696 679.066 7.02124C679.583 7.25553 680.044 7.59581 680.421 8.02001L680.381 6.94001ZM675.191 11.64C675.161 12.4405 675.42 13.2251 675.921 13.85C676.151 14.1238 676.439 14.3431 676.764 14.4918C677.09 14.6406 677.444 14.7151 677.801 14.71C678.175 14.7276 678.548 14.6623 678.894 14.5188C679.24 14.3753 679.55 14.1572 679.801 13.88C680.307 13.2609 680.57 12.4789 680.541 11.68C680.568 10.8844 680.305 10.106 679.801 9.49001C679.558 9.21492 679.257 8.99696 678.92 8.85174C678.583 8.70652 678.218 8.63763 677.851 8.65C677.494 8.64368 677.141 8.71666 676.816 8.86365C676.491 9.01064 676.202 9.22799 675.971 9.5C675.469 10.0974 675.205 10.8598 675.231 11.64H675.191Z" fill="#2F2A1B"/>
      <path id="Vector_128" d="M688.521 14.63L684.271 6.94H686.811L689.701 12.37L692.401 6.94H694.861L687.381 21.54H684.891L688.521 14.63Z" fill="#2F2A1B"/>
      <path id="Vector_129" d="M702.481 0H704.671V7.71999C705.369 7.07147 706.288 6.7138 707.241 6.71999C707.715 6.69424 708.188 6.77017 708.63 6.94266C709.071 7.11515 709.471 7.3802 709.801 7.71999C710.416 8.55179 710.708 9.57905 710.621 10.61V16.4H708.431V10.81C708.481 10.2311 708.341 9.65183 708.031 9.16C707.869 8.98101 707.666 8.84257 707.441 8.75575C707.215 8.66893 706.972 8.63614 706.731 8.66C706.425 8.62757 706.116 8.67502 705.834 8.7977C705.552 8.92038 705.306 9.11411 705.121 9.35999C704.761 10.1307 704.606 10.9816 704.671 11.83V16.4H702.481V0Z" fill="#2F2A1B"/>
      <path id="Vector_130" d="M713.051 3.00999C713.046 2.82307 713.081 2.63726 713.154 2.46486C713.226 2.29246 713.334 2.13742 713.471 2.00999C713.6 1.87513 713.756 1.76825 713.928 1.696C714.1 1.62374 714.285 1.58766 714.471 1.59C714.658 1.58825 714.843 1.6246 715.015 1.69682C715.187 1.76904 715.342 1.87561 715.471 2.00999C715.608 2.13782 715.715 2.29294 715.788 2.46524C715.86 2.63754 715.895 2.82316 715.891 3.00999C715.895 3.19675 715.859 3.38217 715.787 3.55438C715.715 3.72659 715.607 3.88179 715.471 4.00999C715.343 4.14633 715.188 4.2542 715.016 4.32657C714.844 4.39894 714.658 4.43417 714.471 4.42999C714.285 4.43116 714.1 4.39454 713.928 4.32236C713.756 4.25017 713.601 4.14391 713.471 4.00999C713.336 3.88141 713.229 3.72615 713.156 3.55403C713.084 3.38191 713.048 3.19666 713.051 3.00999ZM715.571 6.93999V16.39H713.381V6.93999H715.571Z" fill="#2F2A1B"/>
      <path id="Vector_131" d="M718.911 15.3C718.91 15.1242 718.945 14.9501 719.013 14.7884C719.082 14.6266 719.184 14.4808 719.311 14.36C719.44 14.2263 719.596 14.1214 719.768 14.0525C719.941 13.9835 720.126 13.952 720.311 13.96C720.497 13.9526 720.682 13.9844 720.854 14.0534C721.026 14.1223 721.182 14.2268 721.311 14.36C721.443 14.4899 721.547 14.6459 721.616 14.818C721.685 14.9902 721.717 15.1747 721.711 15.36C721.719 15.5455 721.688 15.7306 721.619 15.903C721.55 16.0754 721.445 16.2312 721.311 16.36C721.182 16.4924 721.026 16.5958 720.853 16.663C720.681 16.7303 720.496 16.7599 720.311 16.75C720.126 16.7606 719.941 16.7312 719.769 16.6639C719.596 16.5966 719.44 16.493 719.311 16.36C719.169 16.2249 719.059 16.0594 718.99 15.8759C718.92 15.6923 718.894 15.4954 718.911 15.3ZM719.161 13.05V1.39999H721.381V13.05H719.161Z" fill="#2F2A1B"/>
      </g>
      </g>
      <defs>
      <clipPath id="clip0_204_109">
      <rect width="1232" height="327.5" fill="white"/>
      </clipPath>
      </defs>
      </svg>

      </div>
      <div v-if="mobileView" class="scroll-down-div">
          <text>scroll down to view projects</text>
          <img class="arrow" src="@/assets/arrow-down.svg">
      </div>
    </div>
  <portfolio-categories/>
  <contact-me/>
</template>

<script>
import PortfolioCategories from "../components/PortfolioCategories.vue";
import ContactMe from "../components/ContactMe.vue";

export default {
  created() {
    window.addEventListener('resize', this.checkScreen);
    this.checkScreen();
    window.addEventListener('scroll', this.reveal);
  },

  name: 'Portfolio',
  components: {
    PortfolioCategories,
    ContactMe
  },

  data () {
    return {
    mobileView: null,
    windowWidth: null,
    clickCount: 0,
    }
  },

  methods : {
    checkScreen () {
        this.windowWidth = window.innerWidth;
        if (this.windowWidth <= 750){
            this.mobileView= true;
            
            return;
        }
        this.mobileView = false;
    },

    reveal () {
      var reveals = document.querySelectorAll(".reveal")

      for (var i = 0; i < reveals.length; i++) {
          var windowHeight = window.innerHeight;
          var elementTop = reveals[i].getBoundingClientRect().top;
          var elementVisible = 200;

          if (elementTop < windowHeight - elementVisible) {
              reveals[i].classList.add("active");
          } else {
              reveals[i].classList.remove("active");
          }               
      }           
    },

    startWave() {
      const arm = document.getElementById('arm')
      const eyes = document.getElementById('eyes')
      const mouth = document.getElementById('mouth')
      const bubble = document.getElementById('bubble')
      const bubble2 = document.getElementById('bubble-2')
      const sayHi = document.getElementById('say-hi')

      if (!sayHi.classList.contains('hide')) {
        sayHi.classList.add('hide')
      }

      if(arm.classList.contains('wave') == false){
        arm.classList.add('wave')
        eyes.classList.add('wave')
        mouth.classList.add('wave')
        this.clickCount += 1;
      }

      if(this.clickCount == 1) {
        bubble.classList.add('appear')
      }
      if((this.clickCount % 3) == 0) {
        bubble2.classList.add('appear')
      }

      setTimeout(() => {this.endWave();}, 2000);

    },

    endWave() {
      const arm = document.getElementById('arm')
      const eyes = document.getElementById('eyes')
      const mouth = document.getElementById('mouth')
      const bubble = document.getElementById('bubble')
      const bubble2 = document.getElementById('bubble-2')
      try {
      arm.classList.remove('wave')
      eyes.classList.remove('wave')
      mouth.classList.remove('wave')
      if (bubble.classList.contains('appear')) {
        bubble.classList.remove('appear')
      }
      if (bubble2.classList.contains('appear')) {
        bubble2.classList.remove('appear')
      }
      }
      catch (e) {
        console.error("Error Loading: ")
      }
    },

    swirlPaintbrush() {
      const paintbrush = document.getElementById('paintbrush')
      if (paintbrush.classList.contains('swirl')){
        paintbrush.classList.remove('swirl')
      } else {
        paintbrush.classList.add('swirl')
      }
    }
  }
}
</script>

<style scoped>
#app {
  text-align: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 120px;
}

.poster-div{
  margin-left: 24px;
  margin-right: 24px;
}

.lp {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 120px;
}

.lp-sh {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 120px;
}

.lp-sh-m {
  text-align: center;
  margin-top: 12px;
  margin-bottom: 48px;
}

@font-face {
  font-family: "Futura-Med";
  src: local("Futura-Med"),
   url("../fonts/Futura-medium-bt.ttf") format("truetype");
  color: #2F2A1B;
}

@font-face {
  font-family: "Futura-Book";
  src: local("Futura-Book"),
   url("../fonts/Futura-Book-font.ttf") format("truetype");
  color: #2F2A1B;
}

.arissa-header {
  width: 40%;
  height: auto;
}

.landing-page-subheader{
    font-family: "Futura-Med";
    font-size: 32px;
}

.landing-page-mobile-subheader {
    font-family: "Futura-Med";
    font-size: 16px;
}
.lp-card {
  background-color: white;  
}

.landing-graphic {
  height: auto;
  justify-content: center;
  align-content: center;
}

.landing-graphic-m {
  height: auto;
}

.reveal {
    position: relative;
    transform: translateY(50px);
    opacity: 0;
    transition: 1s all ease;
}

.reveal.active{
  transform: translateY(0);
  opacity: 1;
}

.svg-animation {
    width: 80%;
    height: auto;
}

#arm {
  animation: none;
}

#arm.wave {
  animation: wave 0.8s 2 ease-in-out;
  transform-origin: bottom;
}

@keyframes wave {
  0% {
    transform: rotateZ(0deg);
  }
  50% {
    transform: rotateZ(6deg); 
  }
  100% {
    transform: rotateZ(0deg);
  }
}

#bubble, #bubble-2 {
  opacity: 0;
}

#bubble.appear, #bubble-2.appear{
  animation: bubble-move ease-in 2s;
  transform-origin: top;
  transform-box: fill-box;
}

@keyframes bubble-move{
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  10% {
    opacity: 1;
    transform: translateY(4px);
  }
  90% {
    opacity: 1;
    transform: translateY(4px);
  }
  100% {
    opacity: 0;
    transform: translateY(0px);
  }
} 

#mouse {
  animation: move-mouse ease-in-out 1s alternate infinite;
}

@keyframes move-mouse {
  from {
    transform: translateX(0px)
  }
  to {
    transform: translateX(10px)
  }
}

#leaf-l {
  animation: leaf-l-move ease-in-out 3s alternate infinite;
  transform-origin: right;
  transform-box: fill-box;
}
@keyframes leaf-l-move {
  from {
    transform: rotateZ(-5deg);
  }
  to {
    transform: rotateZ(10deg); 
  }
}

#leaf-r {
  animation: leaf-r-move ease-in-out 3s alternate infinite;
  transform-origin: left;
  transform-box: fill-box;
  }

@keyframes leaf-r-move {
  from {
    transform: rotateZ(10deg);
  }
  to {
    transform: rotateZ(-5deg); 
  }
}

#plant-l {
  animation: plant-l-move ease-in-out 3s alternate infinite;
  transform-origin: bottom;
  transform-box: fill-box;
}

@keyframes plant-l-move {
  from {
    transform: rotateZ(-2deg);
  }
  to {
    transform: rotateZ(2deg); 
  }
}

#plant-r {
  animation: plant-r-move ease-in-out 3s alternate infinite;
  transform-origin: bottom;
  transform-box: fill-box;
}

@keyframes plant-r-move {
  from {
    transform: rotateZ(4deg);
  }
  to {
    transform: rotateZ(-4deg); 
  }
}


#paintbrush.swirl {
  animation: paintbrush-move ease-in-out 1s 2 alternate;
  transform-origin: bottom;
  transform-box: fill-box;
  cursor: pointer;
}

@keyframes paintbrush-move {
  from {
    transform: rotateZ(0deg) translateX(0px);
  }
  to {
    transform: rotateZ(-20deg) translateX(-5px); 
  }
} 

#eyes, #mouth {
  animation: eyes-move ease-in-out 1s alternate infinite;
  transform-origin: bottom;
  transform-box: fill-box;
}

@keyframes eyes-move {
  from {
    transform: translateX(-6px);
  }
  to {
    transform: translateX(-5px); 
  }
} 

#eyes.wave, #mouth.wave {
  animation: eyes-back-to-norm ease-in-out 2s;
  transform-origin: bottom;
  transform-box: fill-box;
}

@keyframes eyes-back-to-norm {
  0% {
    transform: translateX(-6px);
  }
  20% {
    transform: translateX(0px); 
  }
  80% {
    transform: translateX(0px); 
  }
  100% {
    transform: translateX(-6px);
  }
} 

#face:hover, #body:hover, #hair:hover, #eyes:hover, #mouth:hover, #arm:hover, #paintbrush:hover{
  cursor: pointer
}

#sparkle-l {
  animation: sparkle-move-l ease-in-out 1s alternate infinite;
  transform-origin: center;
  transform-box: fill-box;
}

@keyframes sparkle-move-l {
  from {
    transform: scale(100%, 100%);
  }
  to {
    transform: scale(80%, 80%);
  }
} 

#sparkle-r {
  animation: sparkle-move-r ease-in-out 1s alternate infinite;
  transform-origin: center;
  transform-box: fill-box;
}

@keyframes sparkle-move-r {
  from {
    transform: scale(80%, 80%);
  }
  to {
    transform: scale(100%, 100%);
  }
} 

#say-hi {
  animation: say-hi-move ease-in-out 1s alternate infinite;
  transform-origin: center;
  transform-box: fill-box;
}

@keyframes say-hi-move {
  from {
    transform: translateY(1px)
  }
  to {
    transform: translateY(-1px)
  }
}

#say-hi.hide {
  opacity: 0
}

.scroll-down-div {
  font-family: "Futura-Book";
  font-size: 12px;
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.arrow {
  width: 16px;
  height: auto;
  margin-top: 24px;
  animation: arrow-bounce ease-in-out 1s alternate infinite;
}

@keyframes arrow-bounce {
  from {
    transform: translateY(2px)
  }
  to {
    transform: translateY(-2px)
  }
}
</style>

